import * as React from 'react';
import { SVGProps } from 'react';

const SvgFunctions = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 76 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M8 24a.5.5 0 0 0-.5.5v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3A.5.5 0 0 0 8 24Z"
            fill="#6B360D"
        />
        <path
            d="M10.828 25.172a.5.5 0 0 0-.707 0L9 26.292a.5.5 0 1 0 .707.708l1.121-1.121a.5.5 0 0 0 0-.707ZM10.121 30.829a.5.5 0 1 0 .707-.707L9.708 29a.5.5 0 1 0-.708.707l1.121 1.122ZM7.016 26.308a.5.5 0 1 1-.707.708l-1.137-1.137a.5.5 0 1 1 .707-.707l1.137 1.136ZM7.016 28.985a.5.5 0 0 1 0 .707l-1.137 1.137a.5.5 0 1 1-.707-.707l1.137-1.137a.5.5 0 0 1 .707 0ZM44.207 11.085V6.07a.5.5 0 1 1 1 0v3.793l5.51-5.51a.5.5 0 0 1 .708.707l-5.51 5.51h3.792a.5.5 0 1 1 0 1h-5a.5.5 0 0 1-.5-.486ZM44.5 24.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7ZM47.5 24.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7ZM51 24a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 1 0v-7a.5.5 0 0 0-.5-.5Z"
            fill="#6B360D"
        />
        <circle cx={68} cy={28} r={2} fill="#6B360D" />
        <rect
            x={73}
            y={7.5}
            width={1}
            height={10}
            rx={0.5}
            transform="rotate(90 73 7.5)"
            fill="#6B360D"
        />
        <path
            d="M31.535 31.536a.5.5 0 0 0 0-.708L28.707 28l2.828-2.828a.5.5 0 1 0-.707-.707L28 27.293l-2.828-2.828a.5.5 0 1 0-.707.707L27.293 28l-2.828 2.828a.5.5 0 1 0 .707.708L28 28.707l2.828 2.829a.5.5 0 0 0 .707 0ZM8 3a.5.5 0 0 0-.5.5v4h-4a.5.5 0 0 0 0 1h4v4a.5.5 0 0 0 1 0v-4h4a.5.5 0 0 0 0-1h-4v-4A.5.5 0 0 0 8 3ZM31.07 4.207h-4.999a.5.5 0 1 0 0 1h3.793l-5.51 5.51a.5.5 0 0 0 .707.707l5.51-5.51v3.793a.5.5 0 1 0 1 0V4.706a.499.499 0 0 0-.493-.499"
            fill="#6B360D"
        />
    </svg>
);

export default SvgFunctions;
