import * as React from 'react';
import { SVGProps } from 'react';

const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m45.466 30.313.272.094c2.78 4.667-.906 18.204-4.406 21.593l-12.85-.201L20.064 56 12 40.47l8.843-4.765 3.102-8.304 4.528-.467-7.743-13.807c3.052-4.86 18.836-6.414 23.758-4.02l-1.032 6.26c3.7 1.074 7.109 3.076 8.544 5.05l-6.534 9.896Zm-19.27 0-3.32 7.833-2.649 1.68 4.782 9.9 2.368-.718h12.035c2.3-2.983 4.691-10.736 3.528-15.81l-12.71 2.166-1.046-5.233-2.989.182Zm10.459-18.918a35.493 35.493 0 0 0-8.092.985c1.436 1.867 1.359 5.321-.652 7.562l3.428 6.25 1.245 5.526 1.78-.364-1.458-15.38a8.853 8.853 0 0 1 4.917-1.4 6.432 6.432 0 0 1-1.168-3.16v-.02Zm3.206 6.357c.113 1.867-1.227 4.07-3.356 4.813l.213 4.612c1.811-2.465 5.547-1.68 6.751 1.493l2.6-4.112c-1.649-.934-2.695-3.468-1.988-5.877-1.36-.5-2.78-.807-4.22-.91v-.019Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgMoney;
