import * as React from 'react';
import { SVGProps } from 'react';

const SvgContrary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.456 18.494c-.495-2.023.703-4.49 3.514-4.49a3.373 3.373 0 0 1 2.01.55c.594.39 1.045.958 1.286 1.62l3.108.063a3.102 3.102 0 0 1 1.207-1.453 3.173 3.173 0 0 1 1.837-.498c2.92 0 3.846 2.926 2.85 4.876l4.732 3.9-1.415 2.087-16.644.195s-6.81 7.606-8.028 10.015c-1.217 2.408.758 11.516 7.177 11.516s8.522-9.18 6.557-13.437a49.642 49.642 0 0 0 6.384-6.182l2.87.039a38.382 38.382 0 0 1-6.27 6.68c1.356 3.412-.643 8.95-1.187 11.213-.545 2.262 5.755 4.574 5.755 4.574L48.076 51l-24.963-.063 2.39-3.94-3.648-7.874v-3.077l-2.969 2.565 3.994 8.113-2.52 4.208h-7.423c-2.573-4.184-3.622-6.724-2.474-10.127 3.855-11.58 5.414-12.087 12.417-14.627 5.558-2.038 6.558-3.818 9.576-7.684Zm3.524.931c2.42 0 2.435-3.705-.04-3.705-2.474 0-2.593 3.705.04 3.705Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgContrary;
