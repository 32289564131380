import * as React from 'react';
import { SVGProps } from 'react';

const SvgRational = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.997 49.086c.103 3.238-2.668 4.857-6.849 4.906-4.18.048-19.375-.135-23.36-.065-3.987.07-5.608-2.159-5.69-5.397-.26-10.14.076-30.843.087-32.948 0-3.632 1.448-5.397 4.599-5.435 2.543-.043 22.944-.162 26.203-.146 3.714 0 4.94 2.43 5 5.791.032 1.868-.31 23.51.01 33.294Zm-4.208-4.571-2.825.06-.06-2.613h2.88v-8.533l-10.336.216-.06-2.811 10.39.053v-8.554l-3.226.054-.044-2.58h3.254v-5.056l-27.526.189v4.717h1.681c.223-1.668.955-2.845 2.863-2.845 2.44 0 2.967 1.981 2.967 4.377s-.472 4.318-2.956 4.318c-1.92 0-2.635-1.182-2.868-2.828h-1.676v8.014h1.654c.228-1.662.954-2.838 2.869-2.838 2.434 0 2.96 1.98 2.966 4.382.005 2.401-.472 4.317-2.956 4.317-1.843 0-2.58-1.08-2.841-2.633l-1.681.037v7.81h1.627c.227-1.668.96-2.84 2.868-2.845 2.44 0 2.966 1.981 2.966 4.383 0 2.401-.471 4.317-2.955 4.317-1.844 0-2.581-1.079-2.841-2.639l-1.627.038v4.798l27.477-.076.016-5.23Zm-15.363-5.5c2.435 0 2.961 1.98 2.967 4.383.005 2.401-.472 4.317-2.956 4.317-2.483 0-2.966-1.98-2.966-4.377 0-2.396.515-4.323 2.956-4.323Zm.06-2.38c-2.483 0-2.96-1.98-2.966-4.382-.005-2.402.542-4.318 2.955-4.318 2.413 0 2.961 1.981 2.967 4.383.005 2.401-.472 4.312-2.956 4.317Zm7.592 2.407c2.435 0 2.96 1.98 2.966 4.383.006 2.401-.471 4.317-2.96 4.317-2.49 0-2.961-1.98-2.961-4.382 0-2.402.498-4.312 2.939-4.318h.016ZM27.596 22.598l-.049-2.871 7.706.043c.2-1.738.922-2.98 2.885-2.985 2.44 0 2.966 1.981 2.966 4.383 0 2.401-.472 4.317-2.956 4.317-1.979 0-2.71-1.263-2.89-2.99l-7.662.103Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgRational;
