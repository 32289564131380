import * as React from 'react';
import { SVGProps } from 'react';

const SvgPhlegmatic = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 46 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m41.842 9.174.072-.109a.085.085 0 0 0-.072-.132H39a.143.143 0 0 1-.143-.142V4.808c0-.042-.054-.058-.078-.023l-3.987 6.09-.056.086a.068.068 0 0 0 .056.106h2.877c.077 0 .14.063.14.14v3.861c0 .08.103.109.146.043l3.887-5.937ZM31.842 9.174l.072-.109a.085.085 0 0 0-.072-.132H29a.143.143 0 0 1-.143-.142V4.808c0-.042-.055-.058-.078-.023l-3.986 6.09-.057.086a.068.068 0 0 0 .056.106h2.877c.077 0 .14.063.14.14v3.861c0 .08.103.109.146.043l3.887-5.937ZM21.842 9.174l.072-.109a.085.085 0 0 0-.072-.132H19a.143.143 0 0 1-.143-.142V4.808c0-.042-.055-.058-.078-.023l-3.986 6.09-.057.086a.068.068 0 0 0 .056.106h2.877c.077 0 .14.063.14.14v3.861c0 .08.103.109.146.043l3.887-5.937Z"
            stroke="#6B360D"
            strokeLinejoin="round"
            opacity={0.1}
        />
        <path
            d="m11.842 9.174.072-.109a.085.085 0 0 0-.072-.132H9a.143.143 0 0 1-.143-.142V4.808c0-.042-.054-.058-.077-.023l-3.988 6.09-.056.086a.068.068 0 0 0 .056.106h2.876c.078 0 .142.063.142.14v3.861c0 .08.102.109.145.043l3.887-5.937Z"
            stroke="currentColor"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgPhlegmatic;
