import * as React from 'react';
import { SVGProps } from 'react';

const SvgAspectPeople = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49 38.806c0 .706-.03 1.374-.091 2.004-6.49 2.01-20.773 2.679-27.727.194C16.165 39.216 19.922 35.183 15 32l2.459-2.325c3.48.967 3.09 2.877 4.789 3.997a8.144 8.144 0 0 1 2.337-2.426C20.459 21.496 26.488 16 34.482 16c8.71 0 13.608 4.638 9.806 14.9C47.108 32.65 49 35.294 49 38.805ZM34.493 18.422c-6.254 0-11.077 3.843-7.54 11.973 3.768 3.183 10.993 2.933 14.929-.167 3.342-8.683-.389-11.806-7.389-11.806Zm-6.577 9.973a17.749 17.749 0 0 1 5.07-1.156c-.952-1.176-.346-3.358 1.652-3.358 1.998 0 2.5 2.134 1.64 3.317 1.66.103 3.292.46 4.839 1.06-2.973 3.354-10.426 3.746-13.201.137ZM48.47 43.123C47.238 47.063 43.599 49 34.653 49c-9.136 0-12.516-2.034-13.604-6.011 7.183 2.015 18.107 2.705 27.414.134h.008Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAspectPeople;
