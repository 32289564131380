import * as React from 'react';
import { SVGProps } from 'react';

const SvgUserSquare = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.852 7.575C3.6 8.713 3.5 10.159 3.5 12c0 1.841.1 3.287.352 4.424.252 1.132.648 1.928 1.222 2.502.574.574 1.37.97 2.502 1.222 1.137.253 2.583.352 4.424.352 1.841 0 3.287-.1 4.424-.352 1.132-.252 1.928-.648 2.502-1.222.574-.574.97-1.37 1.222-2.502.253-1.137.352-2.582.352-4.424 0-1.841-.1-3.287-.352-4.425-.252-1.131-.648-1.927-1.222-2.5-.574-.575-1.37-.971-2.502-1.223C15.287 3.6 13.842 3.5 12 3.5c-1.841 0-3.287.1-4.425.352-1.131.252-1.927.648-2.5 1.222-.575.574-.971 1.37-1.223 2.502Zm3.507-4.7C8.603 2.6 10.136 2.5 12 2.5c1.864 0 3.396.1 4.642.376 1.25.278 2.241.741 2.99 1.491.75.75 1.214 1.74 1.492 2.992.277 1.244.376 2.777.376 4.641 0 1.864-.1 3.396-.376 4.642-.278 1.25-.741 2.241-1.491 2.99-.75.75-1.74 1.214-2.991 1.492-1.245.277-2.778.376-4.642.376-1.864 0-3.397-.1-4.641-.376-1.251-.278-2.242-.741-2.992-1.491-.75-.75-1.213-1.74-1.491-2.991C2.599 15.396 2.5 13.864 2.5 12c0-1.864.1-3.397.376-4.641.278-1.251.741-2.242 1.491-2.992.75-.75 1.74-1.213 2.992-1.491Z"
            fill="currentColor"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 7.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5ZM8.5 10a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0ZM8.062 17.085c-.803.388-1.292 1.007-1.58 2.048a.5.5 0 0 1-.964-.266c.35-1.266 1.001-2.147 2.109-2.682C8.69 15.67 10.128 15.5 12 15.5c1.869 0 3.306.158 4.368.657 1.11.522 1.763 1.391 2.114 2.66a.5.5 0 1 1-.964.266c-.287-1.039-.774-1.644-1.575-2.02-.848-.4-2.092-.563-3.943-.563-1.848 0-3.091.176-3.938.585Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgUserSquare;
