import * as React from 'react';
import { SVGProps } from 'react';

const SvgSocial = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m8.53 4.843-.957-2.152c3.027-.952 5.377.476 5.377 3.469H8.411l.12-1.317Zm2.472 4.462h-.796L7.581 6.832h5.398c0 1.468-.943 2.473-1.983 2.473h.006Zm-.008.88c2.265 0 2.322 3.417.158 3.417-1.76 0-2.225-1.607-1.628-2.617l-.928-.91-.964.946c.57 1.01.124 2.58-1.342 2.58-2.569 0-2.38-3.418-.158-3.418.232 0 .462.04.678.121l1.028-.97c-.784.01-1.51.016-1.953.016-1.468 0-2.016-1.208-2.016-2.518h1.403L3.253 4.855c-.756-.743.517-1.446 1.22-1.11l.036.983 5.787 5.588c.22-.088.455-.133.692-.131h.006Zm-4.797 2.421c.902 0 .878-1.43-.065-1.43-.943 0-1.004 1.43.065 1.43Zm4.862 0c.903 0 .879-1.43-.065-1.43-.943 0-1.004 1.43.065 1.43Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSocial;
