import * as React from 'react';
import { SVGProps } from 'react';

const SvgSquare9 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M11.893 8.32c.454 0 .857.09 1.21.27.354.18.627.433.82.76.2.327.3.7.3 1.12v2.44c0 .433-.096.817-.29 1.15a2.012 2.012 0 0 1-.82.78c-.346.187-.75.28-1.21.28-.426 0-.81-.077-1.15-.23a1.995 1.995 0 0 1-.8-.65 1.69 1.69 0 0 1-.28-.96v-.12h.93v.12c0 .293.12.53.36.71.247.18.56.27.94.27.42 0 .757-.12 1.01-.36.254-.247.38-.57.38-.97v-.84c-.4.313-.89.47-1.47.47-.426 0-.806-.083-1.14-.25a1.909 1.909 0 0 1-.77-.7c-.18-.3-.27-.64-.27-1.02v-.27c0-.387.094-.73.28-1.03.194-.307.46-.543.8-.71.34-.173.73-.26 1.17-.26Zm0 3.39c.327 0 .61-.057.85-.17.24-.113.424-.273.55-.48v-.58c0-.387-.13-.7-.39-.94s-.596-.36-1.01-.36c-.406 0-.73.103-.97.31-.233.2-.35.473-.35.82v.26c0 .36.114.64.34.84.234.2.56.3.98.3Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSquare9;
