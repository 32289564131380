import * as React from 'react';
import { SVGProps } from 'react';

const SvgPsychotypesCompatibility = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 140 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.642 20.977c-.222 1.642.286 2.94.618 3.454a4.024 4.024 0 0 1 2.28-.617c1.224 0 2.31.437 2.31 1.39 0 .756-.825 1.522-2.4 1.522-.723 0-1.562-.37-2.276-.966l-.712-.705a3.703 3.703 0 0 1-.758-1.89l-2.106-.567 1.222-1.79c-2.681-1.17-5.6-5.98-5.596-9.485.013-9.153 13.562-8.733 13.552-.141-.006 3.826-3.326 9.035-6.134 9.795Zm2.806 4.75c.968 0 1.398-.38 1.398-.523 0 .044-.048-.389-1.308-.389a3.17 3.17 0 0 0-1.522.334c.408.332.907.534 1.432.578ZM16 6.537c-2.67-.506-5.224 2.224-4.815 4.878l.53-.03c.762-2.05 2.162-3.54 4.285-4.304v-.544ZM14.257 26.4c.723.027 1.26-.35 1.68-.756l.65.737c-.505.477-1.272 1.022-2.246 1.022a2.203 2.203 0 0 1-1.89-1.108l.462-.462c.362.35.841.552 1.344.567Z"
            fill="#B6D1C5"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.06 14.588c-.532 4.915-7.386 8.388-12.875 10.956-4.521-4.021-9.993-9.33-9.22-14.281.9-5.772 7.767-6.438 11.612-1.506l1.213 1.753 1.065-.458-.765-2.228c4.934-2.828 9.547.361 8.97 5.764Z"
            fill="#D7DD93"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.048 24.075c2.495-1.663 1.817-6.35-2.144-6.35-4.778 0-5.046 6.808-.048 6.924h-.016c1.745 0 1.876 2.186.22 2.351-6.783 0-7.981-7.218-4.235-10.287l.724-3.328c1.388-.744 4.749-.701 6.56-.124l.732 3.333c3.187 2.485 2.833 8.017-.978 9.829a2.07 2.07 0 0 0-.022-1.293 2.193 2.193 0 0 0-.793-1.055Zm-2.1-9.597a.927.927 0 0 0-.351.053.886.886 0 0 0-.3.18.83.83 0 0 0-.2.277.79.79 0 0 0 .008.656.832.832 0 0 0 .206.272.927.927 0 0 0 .655.22c1.139 0 1.139-1.658-.019-1.658Zm-1.587-3.935c-.778-1.265-1.15-2.095-.746-2.784l-.825-.15-.429-1.826 2.913.759c1.324-.582 1.938-.152 3.08 1.602 1.112 1.706.946 2.675-.566 3.518-1.511.842-2.447.493-3.427-1.119Zm2.68-1.723c-.08-.133-.17-.26-.268-.383-.126.426-.536.76-1.525.709.09.193.195.38.313.56.536.84.804.996 1.44.655.635-.342.589-.633.042-1.541h-.002ZM84.393 10.2l-.269.924c.75.048 1.49.177 2.209.385l.92 4.198a7.223 7.223 0 0 0-1.236 1.519 3.385 3.385 0 0 0-1.259-.96 3.585 3.585 0 0 0-1.585-.306c-4.824 0-5.05 6.927.083 6.927.603.015 1.2-.114 1.737-.375.128.807.39 1.59.777 2.32a6.623 6.623 0 0 1-2.428.434c-6.791 0-7.997-7.215-4.245-10.288l.705-3.338a5.296 5.296 0 0 1 1.766-.476l1.455-2.447c-.632-1.316-.36-1.963 1.627-3.004 1.986-1.04 2.779-1.012 3.725.534.984 1.615.536 2.339-1.177 3.24-1.23.645-2.12.918-2.808.713h.002Zm-1.36 2.612a.926.926 0 0 0-.35.053.886.886 0 0 0-.299.18.828.828 0 0 0-.2.276.79.79 0 0 0 .008.656.834.834 0 0 0 .206.273.927.927 0 0 0 .655.22c1.136 0 1.136-1.658-.02-1.658Zm3.922-5.836c-.349-.57-.48-.562-1.582 0-.266.119-.509.28-.718.476.334-.006.662.081.943.25.282.169.505.412.641.699l.072-.038c.976-.544 1.005-.805.644-1.387Z"
            fill="#FECE30"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M127.21 26.5c-3.004 0-5.394-1.242-6.731-3.5-1.337-2.259-1.373-5.252-.071-7.78 1.604-3.122 1.49-5.771-.128-7.267-1.143-1.059-2.522-.982-3.805-.118-2.125 1.426-2.503 4.535-1.015 8.989l-1.972.547c-1.923-5.75-.357-9.478 2.005-11.068a4.712 4.712 0 0 1 5.85.468c.798.738 3.177 3.575.326 9.122-1.07 2.081-1.058 4.518.031 6.359 1.089 1.84 3.028 2.8 5.517 2.8 2.083 0 3.833-.769 5.064-2.226a7.412 7.412 0 0 0 1.677-4.726c0-2.451-1.276-4.924-4.124-4.924-1.131 0-2.553 1.334-2.86 2.714a2.089 2.089 0 0 0 2.69-.41l1.363 2.22c-1.07.54-2.853.144-3.945-.517 1.153 1.4 2.929 2.577 3.732 2.22l-.637 2.316c-.825.014-1.89-1.11-2.698-2.285.179 1.06.258 2.135.236 3.21l-2.175.095c.489-1.64.624-3.368.395-5.066-.152 1.303-.69 2.627-1.961 3.298l-1.006-2.041c1.578-.147 2.515-1.65 2.78-2.83a4.266 4.266 0 0 1 1.136-3.07 4.11 4.11 0 0 1 1.346-.955 4.042 4.042 0 0 1 1.604-.344c3.262 0 5.541 2.62 5.541 6.371a8.9 8.9 0 0 1-2.029 5.67c-1.051 1.244-2.973 2.728-6.136 2.728Zm-8.566-2.586c.873 2.52-3.543 3.684-4.016 1.127-.434-2.33-1.221-6.118-1.221-6.118l3.267-.905s1.181 3.628 1.97 5.896Zm-2.266-.348c-.288-.742-.543-1.331-.944-1.268-.591.091-.026 3.174.692 2.996.548-.133.564-.92.252-1.727Z"
            fill="#F6C0B1"
        />
    </svg>
);

export default SvgPsychotypesCompatibility;
