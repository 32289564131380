import * as React from 'react';
import { SVGProps } from 'react';

const SvgPaige = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m12.491 12.539-.164.683-8.67.029-.148-.691 2.716-1.023.151-1.33c-.44-.368-.75-.903-.958-1.553-1.75-1.996-.748-5.904 2.7-5.904 3.56 0 4.357 4.198 2.456 6.114-.177.579-.447 1.061-.847 1.399l.213 1.287 2.551.989Zm-3.282-.8c-.021-.257-.056-.788-.078-1.123a2.8 2.8 0 0 1-1.009.17 2.935 2.935 0 0 1-1.105-.198c-.02.368-.056.99-.068 1.263-.042.826 2.33.841 2.26-.112Zm1.034-5.217a1.439 1.439 0 0 0-.217-.4c-.256.31-.728.714-1.105.768.149-.553.257-1.281.162-1.596-.567.81-1.934 1.552-3.328 1.37.144 2.19.814 3.423 2.324 3.423 1.536.005 2.067-1.344 2.164-3.565Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgPaige;
