import * as React from 'react';
import { SVGProps } from 'react';

const SvgPractice = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.822 11.78c.442 1.486-.364 2.181-.364 2.181l-.734-2.258a4.474 4.474 0 0 1-1.596.27 4.998 4.998 0 0 1-1.911-.35l-.71 2.398s-.8-1.079-.425-2.263c.055-.169.121-.4.21-.672a3.186 3.186 0 0 1-1.205-1.909l.74-.353c.137.48.402.917.767 1.263.363-1.199.783-2.63.987-3.318-1.029-.973-.689-2.978.875-3.362l-.33-1.33 1.324-.098.054 1.416c1.64.36 1.898 2.517.813 3.463.163.524.475 1.534.785 2.547l-.891.693-.92-2.806a2.655 2.655 0 0 1-.778-.042l-1.012 3.426a4.095 4.095 0 0 0 1.595.297c1.698 0 2.75-.983 3.066-2.234l.752.335a3.588 3.588 0 0 1-1.266 2.112l.174.594Zm-2.81-5.594c1.113 0 1.12-1.72-.019-1.72-1.138 0-1.199 1.72.02 1.72Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgPractice;
