import * as React from 'react';
import { SVGProps } from 'react';

const SvgAspectEmotions = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.128 37.49c-2.058 1.433-2.071 3.285-1.767 5.042l-5.11-.568c-.965 3.008-2.93 5.808-6.92 5.645-6.397-.251-10.25-5.23-6.19-14.955 0 0-2.441 0-1.713-3.753l-2.104-.438a6.03 6.03 0 0 1 2.922-3.065c.501-3.472 3.674-3.468 3.674-3.468l-.214 2.092c1.527-1.952 4.676-.592 4.676-.592l-2.027 2.477c2.518-.465 3.467.949 3.467.949l-2.605 1.965c.548 1.463.781 3.341.53 5.68l4.23.368c1.392-4.809 5.123-5.407 5.123-5.407l-.147 4.823c3.86-2.153 7.047 1.22 7.047 1.22s-1.533 1.049-2.872 1.984Zm-15.925-4.582c-3.49 1.21-4.633 5.564-2.338 8.421l.604-.39c-.494-2.938.13-5.047 2.114-7.383l-.38-.648Zm8.146-7.94h-.925s0-3.282-1.587-4.893c-1.586-1.61-4.385-1.821-4.385-1.821l.09-1.003s3.37-.358 4.462-1.464c1.507-1.524 1.356-4.344 1.356-4.344l.952-.064s.167 3.138 1.47 4.438c1.302 1.3 4.639 1.427 4.639 1.427l.037 1.04s-2.843.184-4.472 1.574c-1.34 1.136-1.637 5.11-1.637 5.11Zm-11.93-7.97h-.548s0-1.933-.935-2.882c-.936-.949-2.589-1.072-2.589-1.072l.057-.582s1.984-.214 2.632-.865c.888-.896.798-2.55.798-2.55L25.39 9s.12 1.828.901 2.6c.782.772 2.716.862 2.716.862l.026.615s-1.67.107-2.638.926c-.795.662-.975 2.994-.975 2.994Zm-1.747 2.211s-1.67.107-2.639.926c-.781.668-.962 3.008-.962 3.008h-.547s0-1.932-.936-2.884c-.935-.953-2.588-1.07-2.588-1.07l.057-.584s1.984-.211 2.632-.863c.888-.895.798-2.55.798-2.55l.554-.046s.09 1.858.859 2.623c.768.765 2.745.832 2.745.832l.027.608Zm7.134 30.037L30.098 56l-3.006-.334 1.142-6.844 2.572.424Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAspectEmotions;
