import * as React from 'react';
import { SVGProps } from 'react';

const SvgDna = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M33.899 34.644c-4.637 2.92-9.577 5.203-11.796 10.285l16.54 2.153a12.972 12.972 0 0 0-1.022-3.464l-10.737-1.17 1.84-2.122 6.873.03a29.1 29.1 0 0 0-2.214-2.4l3.175-2.59c3.517 3.876 6.647 8.761 5.813 16.233l-.24-.05-.128-.03-3.252-.686-17.384-3.706c-.138.57-.244 1.148-.317 1.73-.045.379-.064.761-.056 1.144l9.98 2.203-2.75 2.702-6.964-2.087c.21 1.35.465 2.415.465 2.415l-2.97.766s-2.158-3.574-1.688-7.315c.977-7.754 6.345-12.175 11.428-15.528-3.35-3.529-6.617-7.834-6.617-13.486a15.167 15.167 0 0 1 1.14-5.041l2.93.73s-.087 1.08-.158 2.239l17.103 1.24a14.117 14.117 0 0 0 .235-3.317l-13.36-.504 1.36-2.087 11.417-.585a35.782 35.782 0 0 0-1.15-2.869L44.023 8s3.579 5.213 2.889 10.461c-1.064 8.283-7.337 12.614-13.013 16.183Zm-8.18-14.736c.003 1.093.176 2.18.51 3.221l11.351 2.073-4.13 2.046-5.891-1.366c1.222 1.855 2.884 3.423 4.53 4.991 3.927-2.445 7.782-4.749 9.715-8.752l-16.086-2.213Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgDna;
