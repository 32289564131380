import * as React from 'react';
import { SVGProps } from 'react';

const SvgDecisive = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M43.543 55.37c-7.255 1.923-10.831-.85-11.91-4.388-.58-1.898-.175-4.859 1.537-5.31 2.22-.582 3.352.206 5.445-.347 2.928-.774 1.952-2.403 3.445-5.638l-1.776-.961c-2.844 1.812-1.054 4.475-4.123 5.287-2.234.591-3.854-.913-4.05-3.278-.195-2.365 2.386-4.907 2.386-4.907L32.6 28.195l2.757-.75-8.368-14.563-3.37 12.41h3.102l.161 7.83s3.167 1.802 3.6 4.133c.435 2.331-.74 4.201-3.053 4.201-3.177 0-2.147-3.028-5.367-4.052l-1.464 1.404c2.303 2.758 1.786 4.566 4.81 4.566 1.952 0 2.865-.851 4.704-1.02-1.854 2.36-2.244 7.21-.488 10.767-1.58.76-3.654 1.24-6.338 1.24C15.66 54.361 12 50.136 12 42.471c0-6.12 2.357-8.974 3.689-10.094l5.82-.125.18-1.971-5.054-.336.161-4.668h3.206c.663-2.263 3.332-11.343 4.488-15.548.684-2.495 3.733-2.125 4.88.057 2.439 4.557 7.264 13.381 9.148 16.823l3.644-.962 1.381 4.466-4.791 1.615.693 1.86 5.65-1.385c1.58.745 4.61 2.884 6.216 8.816 2.034 7.416-.4 12.405-7.768 14.352Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgDecisive;
