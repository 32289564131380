import * as React from 'react';
import { SVGProps } from 'react';

const SvgActivity = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.202 30.978H32.095c-3.325 0-4.099.316-4.496 1.515-3.726-2.02-3.174-7.866 3.306-9.078 2.54-.474 12.952-2.601 12.952-2.601l1.639 2.763h3.722c2.778 0 6.095.347 7.782 4.444-1.341 1.571-3.024 2.957-8.798 2.957Zm.123-5.38c-2.222 0-2.329 3.343.04 3.343 2.159 0 2.175-3.343-.04-3.343Zm4.39.588a1.155 1.155 0 0 0-.85.32 1.14 1.14 0 0 0 .877 1.957c1.472 0 1.484-2.277-.028-2.277Zm-28.08-3.414.623-5.132-1.659-.509.516-5.131 3.873.62-1.23 2.968 1.587.588-3.71 6.596Zm6.08 11.79h2.57c5.755 0 8.477 5.972 4.961 8.391-.425-1.914-2.48-1.729-6.155-1.729h-2.38c-8.85 0-11.08-7.306-6.505-12.595.62 4.966 1.929 5.933 7.508 5.933ZM14.681 22.298 9 16.468l4.365-3.71 2.778 4.736 1.85-.888 3.404 9.284-4.667-4.713-2.048 1.121ZM12.6 27.165l.572-1.58 6.686 3.613-5.159-.553-.492 1.654-5.158-.45.54-3.868 3.011 1.184Zm12.17 20.998a821.393 821.393 0 0 1 9.326-1.831c5.651-1.062 8.945-3.06 6.774-9.576 7.044 1.58 6.528 13.306-3.464 13.302h-2.08c-.46.608-.996 1.322-1.432 1.942-7.091-2.147-17.956.695-23.183-1.883 4.016-.848 10.715-4.436 10.715-4.436l3.345 2.482Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgActivity;
