import * as React from 'react';
import { SVGProps } from 'react';

const SvgExtravert = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.539 23.183c-.108 1.544-.235 3.43-.378 5.543l1.24.029-1.322 14.962h-.945l-.409 5.88c-.372 4.48-21.583 4.46-22.201.392-.154-.901-.378-3.038-.644-5.812l-1.072.025-1.395-16.099 1.078.03c-.158-1.916-.301-3.705-.424-5.249l-2.65-.03s-2.044-6.15 2.92-6.728l2.12-4.44L42.086 11l2.554 4.714c4.494.98 3.181 7.498 3.181 7.498l-2.282-.029Zm-21.293 25.68c5.73 2.107 11.915 2.043 16.66.048.092-1.22.225-2.999.373-5.116l-17.462.368c.148 1.97.301 3.587.429 4.7Zm8.095-17.775c-6.844 0-7.15 9.997.118 9.997 6.68.005 6.68-9.997-.118-9.997Zm-10.169-8.165c.128 1.75.256 3.524.383 5.278l19.75.46c.128-1.96.25-3.822.353-5.507l-20.486-.23Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgExtravert;
