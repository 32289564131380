import * as React from 'react';
import { SVGProps } from 'react';

const SvgGiver = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m30.106 17.64 13.643 2.543L45 16.36l-1.297-.678-1.154.802c-6.598-1.43-9.334-4.913-10.925-8.484-16.361 2.184-12.917 13.442-10.461 20.836l-3.063-.408c-.405 2.579.45 6.437 1.968 8.564l2.538-1.887c.023 1.788-.326 3.305-.69 4.886-.382 1.663-.781 3.398-.781 5.591 0 13.3 15.754 12.676 15.933 4.794.166-7.624-8.571-6.765-8.571-2.423 0 3.079 3.15 3.176 3.977 1.572L30.92 47.7c1.577-.868 3.375-.186 3.375 1.918 0 4.462-8.18 4.697-8.18-2.303 0-3.106 1.99-5.078 4.391-6.925l-1.347-.961c.155-.817.37-1.623.644-2.41l2.616 1.94c.672-.5 1.343-1.01 1.982-1.55l-3.619-2.557c.154-.28.305-.541.45-.792.239-.41.46-.792.65-1.188l4.138 3a10.68 10.68 0 0 0 1.443-1.95l-5.03-3.544a12.739 12.739 0 0 0-.428-3.03l6.304 4.563a9.225 9.225 0 0 0 .336-2.495c0-3.84-3.784-8.05-8.539-9.747V17.64Zm1.76-5.194a1.214 1.214 0 0 0-1.172.733 1.188 1.188 0 0 0 .295 1.343 1.21 1.21 0 0 0 .903.314c1.552 0 1.561-2.39-.026-2.39Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgGiver;
