import * as React from 'react';
import { SVGProps } from 'react';

const SvgIrrational = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.099 28.946c-3.193.448-3.69-4.146-.69-4.565 2.999-.419 3.61 4.13.69 4.565Zm-38.902 7.572c-2.542 3.99.566 9.547 12.611 9.547l-.993 1.84c-27.477 0-15.322-18.146-12.06-18.41-3.903-.584-10.7 4.648-10.76 11.327l-1.986-.394c-.561-17.593 24.97-19.733 31.38-16.695.14-4.12-8.361-4.604-21.663-.454l-.318-1.866c24.047-12.57 33.446 3.333 27.726 8.24 3.972-.588 10.114-7.666.065-12.075l2.075-.808c16.236 6.18 2.83 21.977-13.838 21.748 3.898 1.62 14.23 2.175 22.15-6.63l.78 1.711c-13.78 17.474-34.564 8.735-35.17 2.919Zm21.549-21.07c-2.404.339-2.776-3.108-.497-3.427 2.28-.32 2.681 3.117.497 3.427Zm-21.937 1.526c-3.187.44-3.684-4.15-.705-4.574 2.98-.424 3.625 4.15.705 4.574ZM7.174 43.91c2.25-.32 2.716 3.123.496 3.432-2.368.354-2.74-3.098-.496-3.432Zm5.193 3.492c2.98-.424 3.61 4.15.696 4.564-3.193.474-3.69-4.12-.69-4.54l-.005-.024Zm22.612-1.377c2.244-.314 2.711 3.123.497 3.432-2.389.339-2.761-3.113-.497-3.432Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIrrational;
