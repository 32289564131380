import * as React from 'react';
import { SVGProps } from 'react';

const SvgFractal = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m52.353 33.828-4.667-1.803-1.76 1.833 1.52 4.757 4.558.692-3.285 3.335 1.691 5.296-5.58-1.329-3.241 3.3-.664-4.229-5.193-1.235-2.675 2.782 1.716 4.392-4.74-1.24L26.556 54l-1.347-4.881-4.77-1.245 3.615-2.935-.984-3.508-3.535-.864-2.813 3.542-1.24-4.506L10 38.294l4.017-4.021-1.239-4.516 4.15 1.606 2.65-2.648-1.061-3.854-4.495-.682 3.359-3.424-1.387-4.99 5.04 1.285 3.388-3.458.694 4.5 4.057 1.033 2.104-2.105-1.475-3.75 4.165 1.083L38.314 10l1.913 5.983 3.934 1.032-2.867 2.332.983 3.123 3.128.805 3.004-3.79 1.347 4.897L54 25.464l-3.068 3.196 1.42 5.168Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgFractal;
