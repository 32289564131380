import * as React from 'react';
import { SVGProps } from 'react';

const SvgVictim = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.524 12.037c1.248-.831.909-3.174-1.072-3.174-2.39 0-2.523 3.403-.024 3.462h-.008c.872 0 .938 1.093.11 1.175-3.392 0-3.99-3.609-2.117-5.144l.361-1.664c.695-.372 2.375-.35 3.28-.062l.367 1.667c1.593 1.242 1.416 4.008-.49 4.914a1.035 1.035 0 0 0-.01-.646 1.097 1.097 0 0 0-.397-.528Zm-1.05-4.798a.463.463 0 0 0-.325.116.415.415 0 0 0-.134.303.395.395 0 0 0 .14.3.445.445 0 0 0 .328.11c.57 0 .57-.829-.01-.829ZM9.68 5.271c-.388-.632-.575-1.047-.372-1.391l-.413-.075-.214-.914 1.456.38c.662-.291.97-.076 1.54.8.556.854.473 1.338-.283 1.76-.756.421-1.223.246-1.714-.56Zm1.34-.861a1.753 1.753 0 0 0-.134-.191c-.063.212-.268.38-.762.354.045.096.098.19.157.28.268.42.402.498.72.327.317-.17.294-.316.02-.77Zm-4.824.69-.134.463c.375.024.745.088 1.104.192l.46 2.099a3.613 3.613 0 0 0-.618.76 1.692 1.692 0 0 0-.629-.48 1.794 1.794 0 0 0-.792-.153c-2.412 0-2.525 3.463.041 3.463.302.007.6-.057.868-.188.064.404.196.796.389 1.16a3.31 3.31 0 0 1-1.214.217c-3.396 0-3.999-3.607-2.123-5.143l.353-1.67c.276-.129.576-.21.883-.237l.727-1.224c-.316-.658-.18-.982.814-1.502.993-.52 1.39-.506 1.863.267.491.807.268 1.17-.589 1.62-.615.322-1.06.46-1.404.357h.001Zm-.68 1.306a.463.463 0 0 0-.324.117.415.415 0 0 0-.134.303.395.395 0 0 0 .14.3.445.445 0 0 0 .328.11c.568 0 .568-.83-.01-.83ZM7.478 3.49c-.174-.285-.24-.281-.79 0a1.29 1.29 0 0 0-.36.238c.167-.003.331.04.472.124.14.085.252.206.32.35l.037-.019c.487-.272.502-.402.321-.693Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgVictim;
