import * as React from 'react';
import { SVGProps } from 'react';

const SvgSupervisor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m51.819 28.31.566-1.597L61 30.173l-1.673 3.049-7.508-4.912Zm.802-4.592 5.658-1.84.534 3.262-5.794-.044-.398-1.378Zm-.088 13.058-3.182-6.08 1.236-.879 4.582 5.042-2.636 1.917Zm-8.33-11.348c-.056-1.593.754-3.356 2.27-5.75-1.387-4.8-13.097-4.828-13.678 3.241 3.105.625 6.22 2.432 9.229 5.715-6.509 5.491-7.801 8.166-7.616 16.353l1.476-.04 1.028-7.227 2.407 1.832 1.092-6.08 2.006 2.432.899-5.783a41.014 41.014 0 0 1 3.439 5.09L35.078 50l-7.624-3.919-6.69 3.299-.963-3.834-4.847-1.106 3.535-2.959-4.145-2.148C9.818 42.247 3.911 42.17 3.911 42.17l1.432-6.65L3 29.553s4.992.04 10.834 3.603a41 41 0 0 1 1.798-2.254l-4.378-3.169 4.414-2.026-.08-4.523 9.153 2.646a14.161 14.161 0 0 1 5.618-1.171c.923-11.611 19.975-12.32 19.975 2.618-.008 3.92-6.007 3.956-6.143.142l.012.008Zm-9.872 5.325c2.962 0 2.745-4.085.185-4.085s-2.693 4.077-.197 4.077l.012.008Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSupervisor;
