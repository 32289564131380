import * as React from 'react';
import { SVGProps } from 'react';

const SvgSwords = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.742 12.716c-.709.728-1.807-.086-1.598-.842L9.88 10.828l-1.627.802-.341-.543.734-.743-1.075-1.05-.983.972.38.666c-.956.938-2.154.294-2.154.294L3.596 12.28l.172.344c-1.072 1.04-2.44-.344-1.394-1.362l.337.152 1.07-1.212S3.185 8.9 4.05 8.06l.66.38 1.037-.926-2.864-2.796L2.686 3l1.755.071 3.04 2.893 2.865-2.562 1.975-.31-.29 1.802-2.778 2.749 1.071 1.018.617-.623.567.344-.775 1.454 1.162 1.364c.792-.164 1.503.84.847 1.516ZM11.29 4.832l.373-1.087-1.144.318S5.825 8.477 5.292 8.957c-.534.48.272 1.36.875.792.603-.569 5.124-4.917 5.124-4.917Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSwords;
