import * as React from 'react';
import { SVGProps } from 'react';

const SvgReasonable = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m50.176 44.608-.117 3.42C52.08 49.818 54 51.058 54 53.89L10 54c0-3.195 2.085-5.218 4.323-7.668l-.05-3.61h2.919a15.494 15.494 0 0 0 2.288-5.975l-3.033-.051c-.499-1.716-.429-4.185 2.748-4.185 2.682 0 6.9.134 8.377.134 2.432 0 3.282 2.457 2.39 4.276l-3.36-.056a16.77 16.77 0 0 0 2.186 5.901h3.119l-.13 3.826c.936.983 1.856 1.827 2.628 2.604.452-.422.928-.856 1.407-1.326l-.047-3.325h2.624a13.723 13.723 0 0 0 1.489-5.329h-2.031c-.417-1.447-.347-3.506 2.3-3.506 2.249 0 4.346.114 5.582.114 2.038 0 2.728 2.055 2 3.582l-2.114-.044a13.082 13.082 0 0 0 1.56 5.25l3.001-.004Zm-7.34-10.52c-5.722 0-5.457-8.283-.093-8.283 5.363 0 5.32 8.284.093 8.284Zm-23.235-3.707-2.982-11.542 12.77-.043-2.823 11.553-6.965.032Zm4.786-13.648-2.81-.142-.102-2.789-2.564-.047-.277-2.276 2.729-.256-.098-2.659L24.227 8l.051 2.954 2.686-.252v3.195l-2.64-.047.063 2.883Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgReasonable;
