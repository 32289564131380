import * as React from 'react';
import { SVGProps } from 'react';

const SvgSuits = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 76 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m62.545 13.5-.148-.685.974-.35.2.403.89-.305-.176-.427 1.509-.544.121-1.11a4.751 4.751 0 0 1-.108-.162c-.826-.197-.997-1.139-.617-1.722a8.888 8.888 0 0 1-.085-.705c-.523-.683-1.705-3.024.23-3.49-.207-1.257.813-1.844 1.582-1.21.582-1.108 2.892-.816 3.006.366a.907.907 0 0 1 1.098 1.258c.775.25.38 2.326-.179 3.072-.007.22-.019.427-.036.621.38.618.231 1.622-.532 1.826-.04.065-.082.122-.122.192l.184 1.104 1.46.525-.202.462.904.341.233-.469.837.301-.164.678-10.859.03Zm5.517-.5 1.632-.718-.11-1.132c-.257.233-.53.447-.817.64l-1.381.027a6.48 6.48 0 0 1-.86-.659l-.071 1.089 1.607.753ZM69.4 10.29a1.767 1.767 0 0 1-1.075-.941h-.621s-.13.756-1.08.956c.277.3.593.56.94.776l.914.03c.347-.225.659-.5.924-.816l-.002-.005Zm.801-3.044c-.37-.807-.62-1.866-.536-2.767-.679.587-2.609 1.498-3.575 1.02-.012.443-.182 1.022-.366 1.517.003.587.056 1.172.16 1.75.402.411 1.259-.017 1.546-.376l1.302.063c.317.433.922.562 1.318.365a9.794 9.794 0 0 0 .153-1.563l-.002-.009Z"
            fill="#2992DE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.992 25.978c-.654 2.12-2.126 3.466-4.521 3.466-2.454 0-3.927-1.394-4.542-3.603l-.929-.78.623-.956c-.03-.356-.046-.724-.046-1.105l9.816.01c0 .373-.016.734-.047 1.084l.654.998-1.008.886Zm-7.12-1.909-.808 1.056.828.992.758-1.018-.777-1.03Zm2.666 0-.921 1.132.958 1.149.879-1.182-.916-1.099Zm2.725 0-.8 1.056.818.992.758-1.018-.776-1.03Zm-3.581 7.918.198-1.844h1.212l.25 1.838c3.994.76 3.899 2.019-.734 2.019-5.08 0-5.18-1.098-.926-2.013Z"
            fill="#6B360D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.39 8.99c.635-2.91-.71-5.545-2.946-5.975 1.22-1.085 4.15-.733 4.15 1.918 0 1.813-1.138 2.772.31 3.881-.428.364-1.033.39-1.515.177Zm-1.383 1.912c0-.591-.015-1.172-.057-1.721-.17.624-.439 1.147-.86 1.502l.206 1.24 2.449.951-.158.659-8.347.027-.143-.666 2.615-.984.146-1.28a2.887 2.887 0 0 1-.764-1.076c-.175.486-.257 1-.243 1.515-1.691-2.408-1.688-6.7 1.608-7.512.31-.093.631-.14.954-.137h.227c2.896.003 4.557 3.956 2.367 7.482Zm-1.418 1.203c-.02-.25-.053-.76-.074-1.082-.313.115-.644.17-.977.165a2.822 2.822 0 0 1-1.063-.193c-.02.355-.055.955-.066 1.217-.04.796 2.243.81 2.175-.107h.005Zm-3.067-3.263c.326 1.086.946 1.677 1.974 1.677 1.457 0 1.976-1.259 2.081-3.34-.176-.465-.42-.8-.751-.936-.43 1.677-2.289 1.44-3.304 2.599Z"
            fill="#559D3E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m51.843 26.377-.277-.751c.257-.156.761-.1 1.073-.405.982-.964-.457-2.4-1.468-1.407-.515.506-.152 1.296-.82 2.419l-.735-.712c.469-.968.264-1.783.84-2.35 1.994-1.958 4.83.806 2.901 2.698-.412.402-.99.349-1.514.508Zm-2.253.992-.865-1.037.513-.434.876.981-.524.49Zm-.263 3.2 1.029-.87.47.486-1.12.886-.379-.501Zm3.988 2.17-.922.761-1.242-1.189.821-.838 1.343 1.266Zm-3.11-1.267.903-.993.524.421-.992 1.023-.435-.451Zm-1.68-3.246 1.238 1.166-.821.807-3.553-3.4.781-.795 1.642 1.549c.251-.24.47-.46.656-.631l.664.702-.607.602Zm-4.055-2.162.981-.905.485.461-1.056.909-.41-.465Zm-.857-.735.877-1.07.516.44-.89 1.037-.503-.407Zm3.594 4.203c-1.824 1.808-3.974 3.944-3.974 3.944l-.725-.842s2.178-2.08 4.007-3.835l.692.733ZM42 23.555l.698-.828 1.368 1.292-.75.795L42 23.554Z"
            fill="#6B360D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m32.397 12.539-.165.683-8.67.029-.148-.691 2.717-1.023.151-1.33c-.44-.368-.751-.903-.959-1.553-1.75-1.996-.747-5.904 2.701-5.904 3.56 0 4.357 4.198 2.455 6.114-.177.579-.447 1.061-.847 1.399l.214 1.287 2.55.989Zm-3.283-.8c-.02-.257-.055-.788-.077-1.123a2.8 2.8 0 0 1-1.01.17 2.935 2.935 0 0 1-1.104-.198c-.021.368-.057.99-.069 1.263-.042.826 2.33.841 2.26-.112Zm1.035-5.217a1.439 1.439 0 0 0-.217-.4c-.257.31-.728.714-1.105.768.148-.553.256-1.281.162-1.596-.567.81-1.935 1.552-3.328 1.37.143 2.19.814 3.423 2.324 3.423 1.535.005 2.067-1.344 2.164-3.565Z"
            fill="#DE64D2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.478 33.947c-8.373-1.02-6.525-12.849 1.336-11.892 7.83.96 6.315 12.829-1.335 11.892Zm-3.585-2.78 1.39-2.68-2.17-1.971c-.46 1.566-.245 3.337.78 4.651Zm2.132-2.007-.792 1.562 1.619-.816-.827-.746Zm.126-2.345-1.604-.194 1.166 1.04.438-.846Zm-2.643-1.276 3.112.38 1.569-3.032c-2.246-.099-3.857 1.073-4.68 2.652Zm4.895-1.063-.789 1.554 1.152.136-.363-1.69Zm-1.25 2.462-.701 1.382 1.286 1.148 1.568-.786-.328-1.522-1.824-.222Zm1.304 3.173 1.429 1.274-.378-1.792-1.05.518Zm.659-7.117.675 3.297 3.193.383c-.393-1.737-1.642-3.255-3.868-3.68Zm1.9 9.278c1.4-1.005 2.136-2.76 2.1-4.484l-2.742 1.361.642 3.123Zm.669-4.782-1.69-.21.197.952 1.493-.742Zm-4.121 3.066-2.855 1.424c1.634 1.277 3.87 1.343 5.324.816l-2.47-2.24Z"
            fill="#6B360D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m9.373 13.704 1.39-2.124c3.215.605 2.92 2.257 1.676 2.762-.4-.314-1.636-.54-3.066-.638Zm1.263-2.614h-.383l-2.138 3.285-.44-.064-2.202-3.187H4.89c-1.544.011-1.733-1.873-.475-2.335l.357-1.752c-.013-1.633-.16-3.2-.832-4.458l.549-.266s.146.218.356.5l.458-.19.356.5-.31.291c.322.347.672.644.936.649.458.007.898-.437 1.098-1.321l-.436-.023-.017-.608h.55c.012-.149.02-.305.02-.475l.593-.011c0 .172.006.334.016.486h.508l-.027.682-.393-.02c.184.878.594 1.261 1.096 1.261.256 0 .635-.33.98-.702l-.28-.237.264-.523.49.216c.212-.267.356-.475.356-.475l.58.22c-.832 1.41-.916 3-.918 4.711l.325 1.545c1.172.45 1.028 2.293-.454 2.302ZM7.454 9.87h.821c0 .452.807.394.554-.3-.441-.387-1.488-.393-1.944-.01-.292.704.57.787.57.31Zm-1.9-3.548c0 1.238.075 2.139.296 2.852l1.268-.648H8.62l1.147.702c.21-.72.272-1.633.272-2.893l-4.486-.013Zm.858 7.366c-1.257.08-2.33.28-2.767.633-1.245-.639-1.697-2.138 1.27-2.732l1.497 2.1Z"
            fill="#EA5C3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.742 32.716c-.709.728-1.807-.086-1.598-.842L9.88 30.828l-1.627.802-.341-.543.734-.743-1.075-1.05-.983.972.38.666c-.956.938-2.154.294-2.154.294L3.596 32.28l.172.344c-1.072 1.04-2.44-.344-1.394-1.362l.337.152 1.07-1.212s-.596-1.303.269-2.142l.66.38 1.037-.926-2.864-2.796L2.686 23l1.755.071 3.04 2.893 2.865-2.562 1.975-.31-.29 1.802-2.778 2.75 1.071 1.018.617-.624.567.344-.775 1.454 1.162 1.364c.792-.164 1.503.84.847 1.516Zm-1.451-7.884.373-1.087-1.144.318s-4.695 4.414-5.228 4.894c-.534.48.272 1.36.875.792.603-.569 5.124-4.917 5.124-4.917Z"
            fill="#6B360D"
        />
    </svg>
);

export default SvgSuits;
