import * as React from 'react';
import { SVGProps } from 'react';

const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M11.458 12.83c0-.327.063-.607.19-.84.127-.233.36-.5.7-.8.247-.213.42-.397.52-.55.1-.153.15-.31.15-.47v-.13a.8.8 0 0 0-.28-.64c-.186-.16-.436-.24-.75-.24-.346 0-.617.09-.81.27-.187.18-.28.43-.28.75v.3h-.9v-.3c0-.567.18-1.017.54-1.35.36-.34.844-.51 1.45-.51.38 0 .717.073 1.01.22a1.593 1.593 0 0 1 .93 1.46v.13c0 .273-.07.53-.21.77-.133.24-.343.483-.63.73-.306.26-.513.473-.62.64-.1.167-.15.367-.15.6v.33h-.86v-.37Zm.44 2.29a.57.57 0 0 1-.42-.17.57.57 0 0 1-.17-.42c0-.173.053-.313.16-.42a.583.583 0 0 1 .43-.17.55.55 0 0 1 .42.17.55.55 0 0 1 .17.42.602.602 0 0 1-.16.43.58.58 0 0 1-.43.16Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgHelp;
