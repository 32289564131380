import * as React from 'react';
import { SVGProps } from 'react';

const SvgDual = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.497 17.738c-1.33 1.11-1.641 5.01-1.641 5.01h-.927s.072-3.194-1.526-4.78c-1.6-1.586-4.46-1.82-4.46-1.82l.089-.973s3.316-.384 4.413-1.473c1.51-1.499 1.42-4.23 1.42-4.23l.944-.078s.17 3.085 1.471 4.366c1.301 1.282 4.678 1.403 4.678 1.403L61 16.19s-2.87.18-4.503 1.548ZM46.03 11.844h-.549s0-1.904-.935-2.839c-.936-.935-2.598-1.048-2.598-1.048l.055-.571s1.994-.21 2.64-.835c.89-.881.8-2.505.8-2.505L45.999 4s.123 1.795.893 2.55c.77.756 2.721.836 2.721.836l.026.605s-1.701.108-2.645.914c-.782.643-.965 2.939-.965 2.939Zm-1.752 2.175s-1.7.104-2.645.91c-.782.655-.965 2.951-.965 2.951h-.549s0-1.9-.935-2.834c-.935-.935-2.594-1.052-2.594-1.052l.051-.576s1.995-.209 2.64-.835c.894-.885.8-2.505.8-2.505l.562-.046s.085 1.829.85 2.58c.765.752 2.755.835 2.755.835l.03.572Zm-3.776 8.274 6.09-2.83.377.446-4.069 4.784.608 9.184-2.976 1.023-3.657-5.343-.246 8.603-3.291 5.085-.455 13.734h-1.837l-1.952-12.64-9.898.12-4.55 4.037-.727 8.304-2.177.07-.617-10.298 3.104-6.68.047-5.401c-1.611-.864-4.095-.597-4.095 6.207l-4.915 1.17c-1.981-11.932 7.692-12.566 10.855-9.185l10.205.13c-.013-4.066 4.044-13.342 14.176-10.52ZM26.104 46.276l-4.175 2.42.922 8.029-2.249.275-2.41-9.088 1.173-1.499 6.739-.137Zm16.157-6.913 2.411 9.221-1.879.701-2.976-6.073-4.172.834 2.654-4.821 3.962.138Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgDual;
