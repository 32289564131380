import * as React from 'react';
import { SVGProps } from 'react';

const SvgQuadrasCompatibility = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 140 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.571 24.297c5.103-6.681.901-15.083-6.453-15.083-5.314 0-8.458 2.906-8.458 7.17 0 8.551 11.313 8.287 11.316 2.28 0-4.242-3.547-4.289-4.904-2.689.049.236.206.708.258.915 2.356-.431 2.108 3.38.258 3.38-2.532 0-4.23-1.038-4.233-3.842 0-6.898 11.847-6.56 11.847 2.12 0 10.25-18.114 10.356-18.202-2.37C4 10.93 8.496 6 15.935 6c11.176 0 15.507 11.51 9.052 19.409l-2.416-1.112Z"
            fill="#DE64D2"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m43.911 27-1.976-2.235C48.653 19.41 34.537 10.4 45.025 5c0 0 1.619 2.122 1.575 2.247-6.747 4.666 7.136 12.99-2.689 19.753Zm7.72 0-1.977-2.235C56.373 19.41 42.257 10.4 52.744 5c0 0 1.62 2.122 1.576 2.247-6.747 4.666 7.135 12.99-2.69 19.753Zm7.72 0-1.977-2.235C64.08 19.41 49.976 10.4 60.462 5c0 0 1.618 2.122 1.574 2.247-6.744 4.666 7.138 12.99-2.686 19.753Z"
            fill="#EA5C3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.644 6.001c.11-.05 2.356 1.31 2.356 1.31-4.68 10.155-14.718-2.363-19.619 4.262L77 9.89C83.092.26 92.426 12.614 96.644 6Zm0 7.214c.11-.05 2.356 1.31 2.356 1.31-4.68 10.155-14.718-2.363-19.619 4.261L77 17.103c6.092-9.63 15.426 2.724 19.644-3.888Zm0 7.214c.11-.05 2.356 1.31 2.356 1.31C94.32 31.893 84.282 19.375 79.381 26L77 24.317c6.092-9.63 15.426 2.724 19.644-3.888Z"
            fill="#2992DE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m134.842 10.846-21.674-.366L113 7.149 135 7l-.158 3.846Zm-.303 6.905h-21.003l-.168-3.324 21.337-.475-.166 3.799ZM134.224 25h-20.313l-.166-3.334 20.655-.46-.176 3.794Z"
            fill="#559D3E"
        />
    </svg>
);

export default SvgQuadrasCompatibility;
