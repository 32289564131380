import * as React from 'react';
import { SVGProps } from 'react';

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 320 320"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m297.943 162.928-100.547-6.139.537 28.292c32.188 20.851 65.732 45.465 86.644 75.313l-25.773 18.672-60.249-64.109 1.215 64.76-30.718.283-2.007-123.918h-15.429l.169 122.758-30.746.283.452-66.769-61.323 66.571-25.434-17.767c19.782-29.028 54.089-55.622 86.842-77.35l.311-26.821L21 163.579l1.64-31.517 93.651-.538c-31.085-21.021-62.708-46.285-81.444-73.728l25.434-17.768 84.044 91.298 28.768-.17L258.804 40l25.773 18.673c-19.952 28.291-51.404 52.198-82.151 72.314l95.574-.566-.057 32.507Zm-139.291-56.584c-34.448.085-34.674-54.037.65-54.037 35.325 0 37.19 54.122-.593 54.122l-.057-.085Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgLogo;
