import * as React from 'react';
import { SVGProps } from 'react';

const SvgAspectTime = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m41.847 24.315-2.336 11.01 1.62.022-.249 2.32-18.81.104-.137-2.69 1.48.037-2.274-10.885h-2.65L18 23.514 31.5 10 45 23.514l-.518.81-2.635-.009Zm-10.377-9.38c-2.677 0-2.803 4.138.045 4.138 2.602 0 2.62-4.139-.045-4.139Zm0 6.627c-8.622 0-9.685 13.65.279 13.65 8.388 0 8.498-13.65-.29-13.65h.011Zm-.48 8.452-3.045-2.9.767-1.045 2.788 1.02 3.803-3.005.689.679-4.03 5.065-.971.186Zm-3.533 9.253h2.13l-.147 2.5c3.063.966 2.695 6.278-.899 6.278-4.3 0-4.21-5.425-1.027-6.305l-.057-2.473Zm5.795.176h2.141l-.06 7.25c3.18.867 2.836 6.307-.79 6.307-4.253 0-4.214-5.309-1.132-6.277l-.16-7.28Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAspectTime;
