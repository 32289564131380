import * as React from 'react';
import { SVGProps } from 'react';

const SvgSuperego = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.059 28.743c7.77-4.701 3.453-25.552 23.113-19.723.617 1.959 1.668 3.257 4.189 3.673 3.572.593 4.77 6.333 2.437 10.624l-13.755.289-.77 2.968c1.604.622 4.229 1.645 4.895 1.925 2.383.98 2.136 3.3-.75 4.8l-.987-1.852-4.44.118-1.085 4.202c2.313 7.347-1.643 10.663-6.596 12.867a8.098 8.098 0 0 0 4.593 3.972c2.51.93 3.098 2.258 2.925 3.335h-9.605l.69-2.145-3.044-4.8.07-4.227h-6.686c-4.662 0-21.53-.592-24.253-20.972l1.43-.378c5.206 9.12 20.84 9.434 27.629 5.324ZM54.246 16.01c1.727 0 1.742-2.674-.034-2.674a1.356 1.356 0 0 0-1.039.346 1.337 1.337 0 0 0-.436.997 1.33 1.33 0 0 0 .455.99 1.35 1.35 0 0 0 1.045.326l.01.015Zm-5.55.705c3.05 0 2.822-4.138.188-4.138-2.635 0-2.768 4.124-.198 4.124l.01.014ZM30.358 46.645s-.824 4.516.271 5.701c1.096 1.186 1.974 2.939 1.772 3.654l-9.142-.059 3.315-9.458 3.784.162Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSuperego;
