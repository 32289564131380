import * as React from 'react';
import { SVGProps } from 'react';

const SvgIdentical = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.685 23.621s.195 5.746.261 8.04c.116 4.064-1.106 7.002-3.531 8.734l.464 15.519-2.03.086-2.359-14.025c-.931.17-1.876.253-2.823.25-5.023 0-6.813-.044-6.813-.044l-8.028 4.543-1.861 8.993-1.965.078.622-10.585c3.365-3.399 4.099-7.942 2.234-10.778l-2.292.279c0-4.014 3.357-6.03 7.174-6.03 3.411 0 12.293.173 12.293.173l3.535-8.619c-2.308-.443-3.99-1.798-3.83-3.579l2.869-.07c-3.254-3.825-.933-8.48-.933-8.48l.958.411a5.201 5.201 0 0 0-.137 3.284 11.1 11.1 0 0 1 1.749-1.712l.584.337s-1.214 1.994-1.512 3.226a10.548 10.548 0 0 0 2.756 2.811l4.559-.11c0 1.341 1.907 2.302 4.199 2.302-.017.045 1.823 4.966-6.143 4.966ZM21.862 35.306a1.306 1.306 0 0 0-.956.359 1.279 1.279 0 0 0-.394.935 1.27 1.27 0 0 0 .415.925 1.294 1.294 0 0 0 .964.338c1.658 0 1.687-2.557-.029-2.557Zm2.545-3.805a1.305 1.305 0 0 0-.956.36 1.279 1.279 0 0 0-.394.934 1.27 1.27 0 0 0 .415.926 1.294 1.294 0 0 0 .964.337c1.67-.016 1.679-2.557-.029-2.557Zm4.85 1.97a1.3 1.3 0 0 0-.972.35 1.277 1.277 0 0 0-.4.943 1.267 1.267 0 0 0 .428.931 1.29 1.29 0 0 0 .98.321c1.659 0 1.671-2.561-.028-2.561l-.009.016Zm12.935-18.21a9.66 9.66 0 0 0 .829-1.831 18.58 18.58 0 0 0-2.035-2.34l.476-.476c.663.289 1.298.635 1.899 1.035a4.73 4.73 0 0 0-.763-2.984L43.473 8a7.495 7.495 0 0 1 1.098 7.281l-2.38-.02Zm-17.64 40.55-2.171.136-2.359-8.66 3.113-2.29 1.417 10.814Zm9.595 0-2.155.095-.713-12.137 3.366.32-.498 11.722Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIdentical;
