import * as React from 'react';
import { SVGProps } from 'react';

const SvgStrategist = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m49.14 33.078-31.886.477-2.534 3.21-1.627-1.158 2.126-3.263-1.982-3.177 1.603-1.36 2.4 3.168h.394l14.678-20.523c.442-3.186 4.8-3.22 5.496-.377-.73-.033-1.248.735-1.33 1.398 0 5.276 8.31 6.788 8.9 19.201l3.432-.038-.288-2.156L56 31.623l-6.523 3.912-.336-2.457ZM32.692 13.52 20.245 30.96l19.608-.224c-.331-10.991-5.626-12.775-7.162-17.197v-.02ZM9.853 36.608 8 35.12l2.4-2.657-1.92-3.087 1.733-1.34 3.331 4.227-3.691 4.346Zm22.805 13.725c1.377-4.498 6.062-6.097 7.022-14.97l5.544-.052c-1.267 10.695-8.77 11.926-8.77 16.878-.004.396.127.782.372 1.095.245.313.59.534.977.627-1.041 3.258-5.011 2.361-5.486-.334L19.04 35.559h2.62l10.998 14.774Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgStrategist;
