import * as React from 'react';
import { SVGProps } from 'react';

const SvgIntrovert = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m41.651 46.329 1.262-3.74c5.67-.82 7.982-7.8.31-5.108-1.49 5.424-5.528 6.727-5.528 11.646l-14.268-.343c0-5.943-7.32-8.248-7.147-16.827.146-6.997 27.21-6.959 27.468.296.017.445.017.874 0 1.285 12.994-2.626 9.382 12.49-2.097 12.79ZM29.79 29.909c-13.394 0-13.071 4.56 0 4.56 14.337 0 13.45-4.56 0-4.56Zm7.616-6.659a4.185 4.185 0 0 0 1.03-3.278c-.315-1.988-1.671-1.795-2.382-4.05-.564-1.782.995-4.601 2.674-5.83l1.43 1.336c-1.02.827-1.008 2.048-.68 3.094.576 1.838 2.031 1.782 2.367 4.109.284 1.937-1.102 4.816-3.014 5.887l-1.425-1.268Zm-9.338-.698c.96-.934 1.64-2.516 1.382-3.625-.47-1.989-2.893-2.443-3.444-4.744C25.51 12.08 27.353 9.23 29.019 8l1.43 1.337a3.34 3.34 0 0 0-1.029 3.428c.43 1.877 2.871 2.48 3.414 4.812.43 1.902-1.451 5.142-3.35 6.23l-1.416-1.255Zm-9.472.698a4.202 4.202 0 0 0 1.029-3.278c-.314-1.988-1.67-1.795-2.381-4.05-.568-1.782.99-4.601 2.67-5.83l1.424 1.336c-1.016.827-1.003 2.048-.675 3.094.576 1.838 2.027 1.782 2.367 4.109.28 1.937-1.102 4.816-3.013 5.887l-1.421-1.268ZM45.876 56l-30.001-.27S12 55.409 12 51.625l37.888-.236C49.896 55.19 45.87 56 45.87 56h.004Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIntrovert;
