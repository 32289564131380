import * as React from 'react';
import { SVGProps } from 'react';

const SvgAggressor = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.605 13.25c-1.502 0-2.697-.621-3.366-1.75-.668-1.13-.686-2.626-.035-3.89.802-1.561.745-2.886-.064-3.633-.571-.53-1.261-.491-1.902-.06-1.063.713-1.252 2.268-.508 4.495l-.986.274c-.962-2.876-.179-4.74 1.002-5.534a2.357 2.357 0 0 1 2.926.234c.399.369 1.588 1.787.163 4.56-.535 1.041-.53 2.26.015 3.18.544.92 1.514 1.4 2.759 1.4 1.041 0 1.916-.384 2.531-1.113.544-.662.841-1.499.839-2.363 0-1.226-.638-2.462-2.062-2.462-.566 0-1.277.667-1.43 1.357a1.045 1.045 0 0 0 1.345-.205l.681 1.11c-.535.27-1.426.072-1.972-.258.576.7 1.465 1.288 1.866 1.11l-.319 1.157c-.412.008-.944-.555-1.348-1.142.089.53.129 1.068.118 1.605l-1.088.048c.244-.82.312-1.685.197-2.534-.075.652-.345 1.314-.98 1.65l-.503-1.021c.789-.074 1.258-.825 1.39-1.415a2.142 2.142 0 0 1 .568-1.535c.19-.203.42-.366.673-.478a2.02 2.02 0 0 1 .802-.171c1.63 0 2.77 1.31 2.77 3.185a4.45 4.45 0 0 1-1.014 2.835c-.525.622-1.487 1.364-3.068 1.364Zm-4.283-1.293c.437 1.26-1.772 1.842-2.008.563-.217-1.165-.61-3.059-.61-3.059l1.633-.452s.59 1.814.985 2.948Zm-1.133-.174c-.144-.371-.271-.666-.472-.634-.295.046-.013 1.587.346 1.498.274-.066.282-.46.126-.864Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAggressor;
