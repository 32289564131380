import * as React from 'react';
import { SVGProps } from 'react';

const SvgSquare6 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M12.173 10.88c.427 0 .804.083 1.13.25.334.167.59.4.77.7.187.3.28.64.28 1.02v.27a1.908 1.908 0 0 1-1.08 1.75c-.34.167-.73.25-1.17.25-.453 0-.856-.09-1.21-.27a2.034 2.034 0 0 1-.83-.76c-.193-.327-.29-.7-.29-1.12v-2.44c0-.433.097-.817.29-1.15.194-.333.464-.593.81-.78.354-.187.76-.28 1.22-.28.427 0 .81.08 1.15.24.34.153.604.37.79.65.194.273.29.59.29.95v.12h-.93v-.12a.839.839 0 0 0-.37-.71c-.24-.18-.55-.27-.93-.27-.42 0-.756.123-1.01.37-.253.24-.38.56-.38.96v.84c.4-.313.89-.47 1.47-.47Zm1.25 1.99c0-.36-.116-.64-.35-.84-.226-.2-.55-.3-.97-.3-.326 0-.61.057-.85.17-.24.113-.423.273-.55.48v.58c0 .387.13.7.39.94s.597.36 1.01.36c.407 0 .727-.1.96-.3.24-.207.36-.483.36-.83v-.26Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSquare6;
