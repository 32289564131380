import * as React from 'react';
import { SVGProps } from 'react';

const SvgQuasidentical = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m51.553 41.863-5.678 1.439 3.93-5.96 4.494.154L57 48.074l-1.954.82-3.493-7.03Zm1.235-15.936-.254 3.65-1.42-.373-1.405 1.644-1.66-3.445-.667.204s-.051 2.756-.051 4.34c0 4.13-1.134 7.272-3.47 9.185l.516 16.775-2.448.093-2.47-14.968c-.89.153-1.793.225-2.696.214l-24.256-.168-3.493 6.83L7 49.088l2.765-10.442 6.775-.535a13.463 13.463 0 0 1-1.106-6.183c.07-2.435.277-8.613.277-8.613-8.365 0-6.453-5.27-6.453-5.27 2.406 0 4.406-1.03 4.406-2.468l4.637.12c1.239-.87 2.299-1.978 3.12-3.258-.332-1.397-1.678-3.655-1.678-3.655l.668-.368a12.43 12.43 0 0 1 1.918 1.946 5.993 5.993 0 0 0-.152-3.752l1.064-.466s2.604 5.299-1.074 9.661l2.913.07c.17 1.909-1.595 3.357-4.019 3.836l3.71 9.228h13.333l3.687-6.71c-.539-.167-2.986-1.736-2.82-3.645l2.848.038c-.332-5.974-3.733-7.827-3.733-7.827l.3-1.145s6.733 2.659 7.115 9.014l4.296.055.603 1.956 3.909 2.1-.415 2.793-1.106.359ZM23.029 36.085a1.425 1.425 0 0 0-1.063.408 1.453 1.453 0 0 0-.438 1.06 1.465 1.465 0 0 0 .461 1.05 1.437 1.437 0 0 0 1.072.383c1.849 0 1.858-2.9-.032-2.9Zm2.844-4.306c-.38.004-.743.16-1.01.436a1.456 1.456 0 0 0 .024 2.047c.272.269.638.417 1.018.413 1.848-.024 1.88-2.896-.032-2.896Zm5.387 2.23a1.424 1.424 0 0 0-1.063.407 1.453 1.453 0 0 0-.438 1.06 1.466 1.466 0 0 0 .461 1.05 1.435 1.435 0 0 0 1.073.383c1.848-.009 1.861-2.923-.033-2.923v.023ZM46.8 16.964c1.149-4.963-1.188-7.449-1.188-7.449l.687-.931s4.608 3.105 3.912 8.469l-3.41-.089Zm-30.818-2.751-2.645.042a8.658 8.658 0 0 1-.453-4.287A8.622 8.622 0 0 1 14.554 6l1 .754c-.65 1.001-.95 2.192-.853 3.385.67-.454 1.38-.847 2.12-1.173l.53.54a20.628 20.628 0 0 0-2.304 2.64c.24.72.553 1.412.935 2.067Zm6.379 43.703-2.765-.102-.037-12.57 4.019-.345-1.217 13.017Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgQuasidentical;
