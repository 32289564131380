import * as React from 'react';
import { SVGProps } from 'react';

const SvgBeneficiary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.788 32.18c-1.493-1.794-.312-3.992 1.633-3.803l.176 2.471c.905.045 2.66-1.556 1.312-3.14-1.176-1.404-3.257-1.476-4.731.588-1.475 2.063 0 6.07-2.805 10.003-3.465 4.863-9.983 3.697-14.548.557a23.978 23.978 0 0 1-1.66 6.639c-1.855 4.486.453 7.586 2.628 7.949 6.216 1.059 7.238-6.088 3.524-6.729-2.714-.449-2.904 1.978-2.41 2.75l2.26-1.086c1.136 1.58-.203 3.697-2.505 3.302-3.9-.66-2.995-8.42 3.429-7.33 6.885 1.17 5.428 14.279-5.144 12.48-5.369-.897-7.957-6.8-6.333-12.076.815-2.611.575-4.818-.144-6.456-1.122 3.912-3.859 7.411-6.822 8.573-8.106 3.185-13.399-6.653-16.583-4.18-.955.74-1.009 2.242.054 3.588 0 0-1.009.66-1.854.126-1.615-1.019-1.869-4.773.334-6.213 5.166-3.378 10.404 4.22 15.218 2.31 3.044-1.207 3.903-3.49 4.31-5.832-7.12 3.373-13.86.188-14.927-5.944-.82-4.75 3.053-8.276 2.623-10.766-.43-2.49-2.293-3.436-3.967-2.67-1.913.871-1.357 2.89-.506 3.249l1.506-1.875c1.837.668 1.9 2.951-.222 3.92-1.669.758-3.401-.099-4.18-1.924-.954-2.243 0-4.773 2.525-5.926 2.895-1.346 6.957.579 7.64 4.535.579 3.356-2.18 7.752-1.692 10.564.701 4.087 7.46 4.639 12.033-.134-5.818-2.185-7.69-6.123-7.69-9.075 0-2.692 1.773-8.322 8.595-8.322 6.373 0 11.607 4.796 10.472 16.598 4.342.225 7.237-3.216 6.69-6.28-.674-3.925-4.044-4.934-4.773-9.17-1.19-6.98 5.605-7.67 7.12-5.028a1.51 1.51 0 0 1-.266 1.714c-1.783-1.472-4.664-.938-4.121 2.211.628 3.656 5.148 4.486 6.233 9.309 1.357 6.06-1.719 10.802-8.006 12.408 2.908 2.929 6.043 4.324 8.174 1.346 1.66-2.329 1.488-6.25 3.47-9.03a5.68 5.68 0 0 1 1.883-1.69 5.731 5.731 0 0 1 7.019 1.155c1.777 2.127 1.596 4.616-.236 6.223-1.497 1.3-3.542 1.525-4.736.112ZM29.61 21.563c-2.465 0-2.528 3.791-.172 3.791 2.796 0 2.592-3.79.172-3.79Zm4.89.696a1.244 1.244 0 0 0-.916.34 1.226 1.226 0 0 0-.379.896 1.216 1.216 0 0 0 .398.887 1.236 1.236 0 0 0 .924.322c1.588 0 1.597-2.445-.027-2.445Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgBeneficiary;
