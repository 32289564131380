import * as React from 'react';
import { SVGProps } from 'react';

const SvgScientific = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.28 6.802c1.827 0 1.815 2.856.03 2.856-1.954 0-1.87-2.856-.03-2.856Zm3.532 5.48c-.094.086-.198.16-.309.223l-.104-.668.012-.01c.473-.436.488-1.174.215-2.016-.897.485-1.857.836-2.85 1.042-.208.045-.416.083-.627.123.366.299.76.561 1.176.783a.86.86 0 0 1 .327-.327.835.835 0 0 1 .444-.107c1.17 0 1.162 1.826.02 1.826-.574 0-.871-.387-.912-.804a8.328 8.328 0 0 1-1.851-1.27 9.218 9.218 0 0 1-1.557.016c.12.98.535 1.562 1.066 1.74.457.151.958-.046 1.437-.491l.492.33c-.648.661-1.374.992-2.11.747-.945-.315-1.372-1.245-1.466-2.396-1.12-.196-1.97-.693-2.155-1.601a2.052 2.052 0 0 1-.041-.385c-.82-.345-.65-1.751.378-1.751 1.092 0 1.157 1.593.229 1.802 0 .07.007.14.02.207.12.583.7.957 1.557 1.13.006-.545.06-1.087.16-1.621-1.168-1.716-1.77-3.623-.72-4.593.6-.55 1.364-.598 2.173-.34l-.24.554c-.664-.176-1.197-.07-1.53.245-.66.606-.38 1.912.494 3.34.051-.2.107-.397.169-.59.444-1.394 1.197-2.853 2.09-3.713-.156-.53.143-1.207.857-1.207 1.168 0 1.161 1.826.02 1.826a.962.962 0 0 1-.523-.14c-.766.748-1.454 2.069-1.884 3.42-.12.376-.214.729-.29 1.064.224.312.48.625.745.934.266.31.54.585.823.85.359-.043.727-.105 1.103-.187a10.069 10.069 0 0 0 2.765-1.007 7.883 7.883 0 0 0-.209-.433l.36-.576c.129.23.245.46.35.69.694-.502 1.098-1.09.968-1.715-.13-.625-.74-1.014-1.58-1.195a11.157 11.157 0 0 1-.532 2.995l-.689.2c.04-.123.08-.235.12-.356.311-.946.49-1.932.53-2.929a9.727 9.727 0 0 0-2.964.209l-.206.049.194-.661c.97-.209 1.965-.276 2.954-.2-.057-.67-.24-1.212-.6-1.52l.107-.666c.68.387 1.002 1.233 1.069 2.268 1.117.211 1.98.726 2.177 1.674.19.923-.386 1.743-1.332 2.396.387 1.108.388 2.14-.32 2.792Zm-5.521-2.28c-.156-.177-.308-.36-.454-.547a7.037 7.037 0 0 0-.077 1.041c.335.029.672.035 1.008.02a10.011 10.011 0 0 1-.477-.514Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgScientific;
