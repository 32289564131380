import * as React from 'react';
import { SVGProps } from 'react';

const SvgMirror = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m50.261 38.298-3.95-11.9-.16 1.013-.24 7.53-5.469 5.706-.514 15.32h-2.225L35.69 41.832l-9.781.139-5.034 4.513-.807 9.285-2.696.079-.523-11.486 3.441-7.498.038-5.738C18.536 30.136 16 30.363 16 37.86l-5.699 1.203c-2.21-13.325 8.363-14.035 11.875-10.27l13.977.08-.505-5.87-8.188-.9s.292-7.804.382-9.926c.09-2.121 2.083-2.548 2.97-1.857l1.013 8.041h5.614c4.714 0 9.66-.06 11.625 2.215 0 0 3.38 13.217 3.851 15.237.108.358.113.738.015 1.098-.098.36-.295.687-.57.944a2.086 2.086 0 0 1-2.1.442ZM41.41 16.58c-6.129 0-5.865-8.58-.1-8.58 5.766 0 5.71 8.58.1 8.58Zm-8.014 27.21-4.52 2.925 1.225 8.974L27.6 56l-3.068-10.158 1.414-1.806 7.448-.246Zm12.148 12.145-2.829.056-.523-14.416 3.771-3.073-.42 17.433Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgMirror;
