import * as React from 'react';
import { SVGProps } from 'react';

const SvgQueen = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.357 11.807h-.575l.094.562 2.487.966-.16.669-8.478.028-.146-.671 2.657-1 .059-.522h-.543c-.865.006-1.31-.58-1.32-1.18-1.609-.324-1.593-2.602.164-2.913-.246-1.881.941-3.978 3.442-3.978 2.48 0 3.588 2.082 3.352 3.961 1.784.209 1.865 2.606.214 2.9.003.591-.411 1.172-1.247 1.178Zm-1.2.752c-.02-.252-.054-.771-.076-1.099a2.739 2.739 0 0 1-.99.167 2.87 2.87 0 0 1-1.08-.194c-.021.36-.056.968-.068 1.235-.04.808 2.279.822 2.21-.11h.004Zm.796-5.494c-.251.303-.712.699-1.08.752.144-.542.25-1.254.158-1.56-.556.795-1.894 1.529-3.256 1.344.14 2.14.797 3.347 2.272 3.347 1.497 0 2.018-1.308 2.117-3.463a1.396 1.396 0 0 0-.211-.42Zm1.43-4.988-1.128 1.536-2.226-.58-.303-1.944c-.542-.063-.66-.905-.05-1.068.61-.163.91.675.463.972.215.868.798.97 1.297.142-.44-.324-.12-1.115.489-.952.609.163.451 1.035-.082 1.073.041.887.393 1.144 1.24.54-.276-.512.418-1.08.878-.62.46.46-.098 1.098-.579.901Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgQueen;
