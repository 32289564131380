import * as React from 'react';
import { SVGProps } from 'react';

const SvgApps = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14 17.5c0-1.404 0-2.107.337-2.611.146-.218.334-.406.552-.552C15.393 14 16.096 14 17.5 14c1.404 0 2.107 0 2.611.337.218.146.406.334.552.552.337.504.337 1.207.337 2.611 0 1.404 0 2.107-.337 2.611a1.999 1.999 0 0 1-.552.552C19.607 21 18.904 21 17.5 21c-1.404 0-2.107 0-2.611-.337a1.999 1.999 0 0 1-.552-.552C14 19.607 14 18.904 14 17.5ZM3 17.5c0-1.404 0-2.107.337-2.611a2 2 0 0 1 .552-.552C4.393 14 5.096 14 6.5 14c1.404 0 2.107 0 2.611.337a2 2 0 0 1 .552.552C10 15.393 10 16.096 10 17.5c0 1.404 0 2.107-.337 2.611a2 2 0 0 1-.552.552C8.607 21 7.904 21 6.5 21c-1.404 0-2.107 0-2.611-.337a2 2 0 0 1-.552-.552C3 19.607 3 18.904 3 17.5ZM3 6.5c0-1.404 0-2.107.337-2.611a2 2 0 0 1 .552-.552C4.393 3 5.096 3 6.5 3c1.404 0 2.107 0 2.611.337a2 2 0 0 1 .552.552C10 4.393 10 5.096 10 6.5c0 1.404 0 2.107-.337 2.611a2 2 0 0 1-.552.552C8.607 10 7.904 10 6.5 10c-1.404 0-2.107 0-2.611-.337a2 2 0 0 1-.552-.552C3 8.607 3 7.904 3 6.5Z"
            stroke="currentColor" fill="none"
        />
        <path
            d="M14 6.5h7M17.5 3v7"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgApps;
