import * as React from 'react';
import { SVGProps } from 'react';

const SvgPositivist = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.096 44.838c-3.564 8.41-16.394 3.148-13.788-4.035 1.817-4.992 4.098-14.485 4.566-21.168.47-6.684 4.937-9.209 9.923-8.528 4.986.682 10.105 6.13 9.068 12.195-1.037 6.066-6.21 13.127-9.77 21.536Zm1.456-24.38-.242-5.614-2.606-.21-.627 5.658c-6.822 4.03-7.464 14.17-7.464 14.17l2.853.54s.35-9.518 6.087-12.75c3.41 3.963 2.32 8.126-1.21 14.608l2.355 1.388c5.919-9.748 3.668-14.45.854-17.79Zm-20.975 27.65c.035 7.635-13.822 8.336-14.35-.78-.528-9.114-3.016-17.485-1.975-23.535 1.042-6.051 7.74-9.493 12.657-8.483 4.917 1.01 8.289 4.874 6.477 11.356-1.812 6.482-2.863 16.132-2.809 21.442Zm-2.903-28.797-2.527-.657-2.103 5.217c-3.776 2.212-7.484 5.884-5.198 17.069l2.685-.544c-1.14-7.277-.765-11.563 3.787-14.162 4.319 4.938 1.441 14.02 1.441 14.02l2.878.426s2.809-9.768-2.26-15.828l1.297-5.541Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgPositivist;
