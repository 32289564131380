import * as React from 'react';
import { SVGProps } from 'react';

const SvgIntuit = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M55.638 37.66C40.207 53.726 15.418 48.29 9.28 40.006a29.182 29.182 0 0 1 3.79-6.834L8 28.479l2.359-2.518 4.852 4.706a23.128 23.128 0 0 1 4.912-3.86l-4.037-6.864 3.045-1.736 3.95 7.163a21.62 21.62 0 0 1 6.534-1.476L29.697 16H33.1l-.246 7.91c2.415.19 4.79.727 7.055 1.594l3.727-7.763 2.971 1.303-3.45 7.875a34.547 34.547 0 0 1 5.447 3.57l4.52-5.888L56 26.928l-4.77 5.875a46.478 46.478 0 0 1 4.408 4.858Zm-36.62-5.878a25.473 25.473 0 0 0-4.51 5.04l.983 4.09a28.252 28.252 0 0 0 7.383 3.344c-3.536-3.078-4.778-8.01-3.855-12.474Zm19.063-3.447c-4.628-1.555-9.242-1.537-13.451.091-5.227 5.275-3.187 16.533 7.038 16.533 9.38 0 11.597-11.388 6.413-16.624Zm5.39 2.535c1.11 4.234.26 9.053-2.518 12.37a43.734 43.734 0 0 0 10.42-5.8 37.042 37.042 0 0 0-7.901-6.57Zm-11.91 10.573c-8.268 0-7.893-11.892-.135-11.892 7.759 0 7.664 11.892.134 11.892Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIntuit;
