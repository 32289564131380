import * as React from 'react';
import { SVGProps } from 'react';

const SvgQuadras = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 76 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m23.956 13.5-.988-1.117c3.359-2.678-3.7-7.183 1.545-9.883 0 0 .809 1.061.787 1.123-3.373 2.334 3.568 6.495-1.344 9.877Zm3.86 0-.989-1.117c3.36-2.678-3.699-7.183 1.545-9.883 0 0 .81 1.061.788 1.123-3.374 2.334 3.567 6.495-1.345 9.877Zm3.86 0-.989-1.117C34.04 9.705 26.988 5.2 32.231 2.5c0 0 .81 1.061.787 1.123-3.372 2.334 3.57 6.495-1.343 9.877Z"
            fill="#EA5C3D"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.322 3c.055-.025 1.178.656 1.178.656-2.34 5.077-7.359-1.182-9.81 2.13l-1.19-.841C45.546.13 50.213 6.307 52.322 3Zm0 3.608c.055-.026 1.178.655 1.178.655-2.34 5.077-7.359-1.182-9.81 2.13l-1.19-.841c3.046-4.816 7.713 1.362 9.822-1.944Zm0 3.606c.055-.025 1.178.656 1.178.656-2.34 5.077-7.359-1.182-9.81 2.13l-1.19-.842c3.046-4.815 7.713 1.363 9.822-1.944Z"
            fill="#2992DE"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.421 5.423 62.584 5.24 62.5 3.574l11-.074-.079 1.923Zm-.151 3.452H62.768l-.084-1.661 10.669-.238-.083 1.9Zm-.158 3.625H62.956l-.083-1.667 10.327-.23-.088 1.897Z"
            fill="#559d3E"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.286 12.148c2.551-3.34.45-7.54-3.227-7.54-2.657 0-4.229 1.452-4.229 3.584 0 4.276 5.657 4.144 5.658 1.14 0-2.12-1.773-2.144-2.452-1.344.024.118.103.353.129.457 1.178-.216 1.054 1.69.129 1.69-1.266 0-2.115-.519-2.117-1.921 0-3.449 5.924-3.28 5.924 1.06 0 5.125-9.057 5.178-9.101-1.185C2 5.465 4.248 3 7.968 3c5.588 0 7.753 5.755 4.526 9.704l-1.208-.556Z"
            fill="#DE64D2"
        />
    </svg>
);

export default SvgQuadras;
