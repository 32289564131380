import * as React from 'react';
import { SVGProps } from 'react';

const SvgParagraph = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M12 17.063c-.277 0-.526-.048-.749-.144a1.598 1.598 0 0 1-.556-.374 1.605 1.605 0 0 1-.344-.55 1.933 1.933 0 0 1-.122-.693h.951c0 .55.274.825.82.825.546 0 .82-.282.82-.847a.835.835 0 0 0-.071-.353.834.834 0 0 0-.253-.297 2.444 2.444 0 0 0-.496-.308 14.298 14.298 0 0 0-.79-.363 2.332 2.332 0 0 1-.97-.76c-.244-.337-.365-.737-.365-1.199 0-.418.111-.785.334-1.1.223-.323.526-.573.91-.749a2.622 2.622 0 0 1-.647-.572c-.162-.22-.243-.506-.243-.859 0-.25.04-.484.122-.704.08-.22.195-.407.344-.561a1.6 1.6 0 0 1 .556-.374c.223-.096.472-.144.749-.144s.523.048.739.144c.222.088.408.212.556.374.155.161.274.352.354.572.081.22.122.462.122.726h-.951c0-.594-.274-.891-.82-.891-.256 0-.459.077-.607.231-.142.154-.213.363-.213.627 0 .132.024.25.071.353a.926.926 0 0 0 .253.308c.122.095.287.194.496.297.21.103.472.224.79.363.404.176.728.437.97.781.244.338.365.734.365 1.189 0 .418-.115.785-.344 1.1a2.23 2.23 0 0 1-.9.738c.256.161.468.352.637.572.168.22.253.506.253.859 0 .256-.04.491-.122.704-.08.22-.199.407-.354.561a1.482 1.482 0 0 1-.556.374 1.81 1.81 0 0 1-.739.143Zm0-3.874c.344 0 .627-.11.85-.33.23-.22.344-.503.344-.848 0-.169-.03-.326-.091-.473a1.078 1.078 0 0 0-.253-.374 1.095 1.095 0 0 0-.384-.253 1.087 1.087 0 0 0-.466-.1c-.344 0-.63.114-.86.342-.223.22-.334.502-.334.847s.111.631.334.858c.23.22.516.33.86.33Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgParagraph;
