import * as React from 'react';
import { SVGProps } from 'react';

const SvgIllusionary = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M44.31 14.665c0 3.38-.162 10.047-.079 13.03.134 4.564-1.223 7.87-3.932 9.817l.512 17.391-2.256.097-2.626-15.724a17.263 17.263 0 0 1-3.148.271c-5.593 0-7.587-.05-7.587-.05l-8.934 5.092-2.073 10.093L12 54.77l.697-11.87c3.743-3.817 4.564-7.598 2.483-10.775l-2.552.309c0-4.499 3.724-6.76 7.988-6.76 3.798 0 14.685.194 14.685.194l.042-3.527a4.727 4.727 0 0 0-1.916.948l-4.065-1.952c-.231-3.223 1.264-6.446 6-6.575v-.097c0-6.492-13.615.608-15.577-7.077-1.213-4.72 1.643-5.691 1.643-5.691l1.846 3.08L25.18 3.72l.526-3.508L26.58 0a25.305 25.305 0 0 1 1.675 3.684l2.35 1.27 1.167-3.471.923.046c.404 1.604.637 3.246.697 4.899l1.42.755 2.28-2.947c3.467 1.985-.078 5.12.878 8.131l3.48.033.276-.033c.932-3.01-2.727-6.492.803-8.384l2.054 3.223 1.468-.764c.099-1.715.388-3.414.863-5.065h.923l1.08 3.596 2.455-1.28A25.29 25.29 0 0 1 53.157.097l.863.24.309 3.425 1.92 1.418 1.938-2.988s2.801 1.063 1.44 5.737c-2.202 7.616-15.317.248-15.317 6.736ZM23.736 54.784l-2.423.151-2.607-9.71 3.47-2.574 1.56 12.133Zm10.684.023-2.4.105-.799-13.615 3.748.36-.55 13.15Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgIllusionary;
