import * as React from 'react';
import { SVGProps } from 'react';

const SvgSquare0 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M12.005 15.12a2.53 2.53 0 0 1-1.2-.28 2.103 2.103 0 0 1-.83-.77 2.191 2.191 0 0 1-.29-1.12v-2.44c0-.42.097-.793.29-1.12.2-.333.477-.593.83-.78a2.53 2.53 0 0 1 1.2-.28c.447 0 .844.093 1.19.28.354.187.627.447.82.78.2.333.3.707.3 1.12v2.44c0 .413-.1.787-.3 1.12a2.032 2.032 0 0 1-.82.77 2.47 2.47 0 0 1-1.19.28Zm0-.87c.427 0 .764-.12 1.01-.36.247-.247.37-.58.37-1v-2.33c0-.413-.126-.743-.38-.99-.246-.247-.58-.37-1-.37-.426 0-.766.123-1.02.37-.246.24-.37.57-.37.99v2.33c0 .413.124.743.37.99.254.247.594.37 1.02.37Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSquare0;
