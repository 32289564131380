import * as React from 'react';
import { SVGProps } from 'react';

const SvgCockring = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.03 40.248c1.557 2.95 2.752 6.43.914 7.963-1.605 1.336-4.492.334-7.134-1.142a14.516 14.516 0 0 1-6.288 2.216c-1.558 2.714-3.577 5.223-5.711 4.626-2.077-.545-2.861-3.541-3.116-6.575l-.332-.184a23.642 23.642 0 0 1-4.154-2.962c-3.323.936-7.269 1.476-8.442-.709-1.1-2.056 1.038-4.93 3.338-7.243a15.273 15.273 0 0 1-.43-6.271c-2.69-2.02-5.457-4.745-4.47-7.017.986-2.273 5.108-2.334 8.603-1.928a17.85 17.85 0 0 1 3.338-2.987c-.125-3.372.275-7.335 2.741-7.957 2.368-.592 4.902 2.298 6.75 5.11 2.022.07 4.013.508 5.873 1.295 2.398-1.84 5.192-3.306 6.993-1.964 1.802 1.342 1.278 4.442.307 7.269a15.087 15.087 0 0 1 2.777 5.238c3.157.797 6.449 2.185 6.413 4.54-.037 2.354-3.261 3.798-6.402 4.718a19.67 19.67 0 0 1-1.568 3.964ZM14.945 22.482c-2.321-.046-2.773 2.755-.14 2.57 2.632-.185 4.179-2.483.14-2.57Zm-.167 17.005c-1.65 1.609.057 3.886 1.76 1.892 1.703-1.995 1.127-4.699-1.76-1.892Zm21.807 6.41c2.96-.329 4.206-1.963 4.59-3.45.348-1.361-.981-1.798-1.516-1.027-.815 1.218-2.378 1.979-3.94 2.267-1.148.236-1.19 2.442.882 2.215l-.016-.005ZM24.223 14.715c-.218 2.287 2.596 2.94 2.596.328 0-2.611-2.233-4.313-2.596-.329Zm3.406 3.13c-4.429.663-6.23 2.935-6.942 5.14-.384 1.219.706 1.754 1.516.566 1.226-1.81 3.578-2.95 5.94-3.352 1.744-.323 1.547-2.662-.53-2.354h.016Zm6.428 4.514c-12.95-3.373-19.325 16.264-4.382 20.16 12.6 3.286 17.565-16.727 4.382-20.16Zm6.329-5.316c-1.78 1.476-.26 3.881 1.6 2.026 1.858-1.856 1.495-4.596-1.6-2.026Zm9.06 12.41c-2.134-.89-3.603 1.542-1.085 2.338 2.518.797 4.813-.76 1.085-2.338Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgCockring;
