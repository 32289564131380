import * as React from 'react';
import { SVGProps } from 'react';

const SvgParallel = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m50.288 40.7-8.68.116.386-7.19 3.762 2.664c.456-1.463 2.095-1.914 3.452-1.058l.911-2.46c.456-1.21 1.667-.92 1.881-.406l-1.712 8.335Zm-4.832-17.97c1.334 3.004 1.02 5.754.455 6.21-.742.584-3.858 2.603-3.858 2.603l-2.377-2-.055 13.145 2.824 3.546-.155 6.393h-6.139l-.118-1.274 1.63-1.04v-1.54s-3.611-3.523-4.554-4.498c-1.23-1.274-1.512-1.09-1.512-3.574l-5.183.041-9.314 8.404 1.572 2.184-.397 1.541-6.649-.032s-1.694-2.341-2.277-3.22c-.583-.878-.455-1.927.533-3.008.988-1.08 2.414-2.46 2.414-2.46l.072-2.76s-3.147-6.564.383-10.35c3.53-3.785 11.582-11.7 12.802-12.933 1.22-1.233 2.418-1.983 4.86-1.983h2.172c1.139-6.38 7.592-6.057 10.129-3.072 1.972 2.3 5.164 7.295 5.164 7.295s-1.88 1.743-2.422 2.382Zm-6.668-4.843c-2.245 0-2.304 3.519-.155 3.519 2.55 0 2.355-3.519.155-3.519Zm4.932.529a1.232 1.232 0 0 0-.922.35 1.255 1.255 0 0 0-.381.918 1.267 1.267 0 0 0 .4.91 1.239 1.239 0 0 0 .93.329c1.59 0 1.604-2.507-.027-2.507ZM28.336 43.028l.16 7.304 1.694 1.021-.406 1.509L21.4 53l-.096-4.544L27.134 43l1.202.027Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgParallel;
