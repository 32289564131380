import * as React from 'react';
import { SVGProps } from 'react';

const SvgSensory = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.573 51.914 28.633 54C27.285 50.027 21.173 42.345 11 39.326c1.142-4.534 3.96-5.463 7.84-4.604.698.16 2.504.835 3.769 1.212.234.606 1.323 3.243 1.323 3.243l1.186-.348c-.444-3.5-1.738-13.974-2.69-24.333-.342-3.754 4.608-4.31 5.331-.158.547 3.163 2.065 14.664 2.065 14.664l1.606.035s.083-13.373.054-16.05c-.044-3.774 5.301-4.196 5.37.03.034 2.91.112 16.884.112 16.884l1.274.347s1.89-12.678 2.207-15.295c.449-3.739 5.677-3.292 5.17.894-.337 2.83-2.334 15.97-2.334 15.97l1.162.423s3.246-9.182 3.866-11.179c.976-3.242 5.639-1.733 4.516 1.902a158.966 158.966 0 0 0-6.254 28.951Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSensory;
