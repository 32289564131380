import * as React from 'react';
import { SVGProps } from 'react';

const SvgProcess = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.75 51.997c-3.68 0-5.25-2.717-5.002-5.22l-1.683.127c.236 2.48-1.171 5.093-4.221 5.093-3.28 0-4.883-2.146-5.02-4.396l-1.647.122c-.116 2.205-1.527 4.274-4.244 4.274-2.88 0-4.465-1.657-4.883-3.586l-1.869.14a4.147 4.147 0 0 1-1.439 2.524 3.997 3.997 0 0 1-2.715.922c-6.832 0-6.388-9.317-.555-9.462H48.43c5.975.09 6.099 9.462.32 9.462ZM15.458 40.104c-4.434-7.755 2.149-14.546-.253-25.72l1.496-1.276 5.864 1.358c1.199-2.02 3.334-2.603 7.546-2.44l-.12 4.178 1.274.3v2.716l-4.439-.118.444 3.015 3.995-.249c.019.82.08 1.637.182 2.45l-3.733.728.404 2.775 4.08-.76c.24.488.537.945.887 1.358l-2.663 2.82 2.348 1.884 2.375-2.993c.644.37 1.317.684 2.01.942l-1.424 3.368 2.588 1.173 1.487-3.694c7.289 2.182 10.48 5.346 8.066 8.714l-32.414-.53Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgProcess;
