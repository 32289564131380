import * as React from 'react';
import { SVGProps } from 'react';

const SvgHumanitarian = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.298 13.584 3.54 2.66l6.972-.244 2.19 1.89-.184 9.16-9.219.117Zm5.495-1.52c.221-.17 1.098-1.028 1.098-1.028l-.856-.138-.244-1.64s-.946.435-1.77.185c.013.093.032.376.036.478-2.222 1.211-.987 2.403 1.736 2.14v.003Zm-.19-5.261c-.01-.702-.307-1.065-1.01-1.066-.904 0-.966.794-.964 1.124-.004.224.007.447.03.67 0 .867.35 1.002.459 1.113a.51.51 0 0 0 .44.276c.365 0 .42-.157.47-.21.08-.083.405-.232.403-.552 0-.57.195-.553.203-.69.01-.187-.026-.387-.03-.67v.005Zm1.261-1.81L9.79 3.218l-5.57.229-.14 8.286c.43-.887 1.288-1.51 1.883-2.264-.339-.737-.371-2.102-.158-2.38a.94.94 0 0 1-.074-.454c0-.205.294-.528.365-.737.135-.32.364-.592.657-.783.293-.19.638-.29.989-.286 1.854-.027 2.116 1.077 2.24 1.95.107.717.208 1.36.28 1.782.077.399.127 1.483.641 1.99.235.2.483.384.742.552l.094.131.14-6.214-2.014-.025Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgHumanitarian;
