import * as React from 'react';
import { SVGProps } from 'react';

const SvgEarthMini = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m10.11 18.793-1.378-1.378v-1.416h-1.3v1.954L8.81 19.33v2.39l3.614 3.615v3.291h1.3v-3.83l-3.614-3.614v-2.39Zm15.512 3.21h1.3v-1.306h-1.3v1.306ZM15.35 29.28h1.3v-1.304h-1.3v1.304ZM5.202 20.776h1.3V19.47h-1.3v1.306Zm2.118 4.397h1.3v-1.305h-1.3v1.305Zm2.076 2.077h1.3v-1.306h-1.3v1.306Zm17.629-15.377-.946.946-6.612-6.613-2.86 2.86v2.765l-3.004 3.004-.423-.422v-4.296h-3.1l-2.28 2.28H4.158l-1.609 1.608.92.92 1.227-1.228H6.5L2.716 17.48l.92.919 6.982-6.983h1.262v2.615h-1.302v1.3h1.683l2.296 2.296v2.367l-1.221-1.22-.92.918 4.522 4.522.92-.92-2.001-2V17.09l-1.335-1.335 3.386-3.386V9.604l1.559-1.56.053.053v5.937l-1.904 1.903 3.737 3.737v1.813l3.679 3.679.92-.92-3.299-3.297v-1.813l-3.199-3.199 1.366-1.364V9.398l4.34 4.34-2.926 2.924.92.919 3.871-3.87.579.579v2.363h1.3v-2.902l-1.879-1.878Zm-8.22 12.374-.92.919 2.56 2.559h2.248v-1.3h-1.71l-2.178-2.178Zm1.8-1.874-.922-.923-.92.92.924.922.918-.92Zm-3.415-3.964v1.306h1.3v-1.306h-1.3Zm10.414.854h1.3v-1.305h-1.3v1.305ZM21.35 23.87v1.3h1.305v-1.3H21.35ZM16 30.7c-8.106 0-14.7-6.595-14.7-14.702C1.3 7.894 7.894 1.3 16 1.3c8.105 0 14.7 6.595 14.7 14.7C30.7 24.106 24.105 30.7 16 30.7ZM16 0C7.178 0 0 7.177 0 16c0 8.822 7.178 16 16 16s16-7.178 16-16c0-8.823-7.178-16-16-16Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgEarthMini;
