import * as React from 'react';
import { SVGProps } from 'react';

const SvgAspectLogic = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m43.177 49.093-22.223.371-.226 1.51L19 51V12.275L45 12v38.926l-1.593.026-.23-1.859Zm-.219-34.719L20.72 14.5l.088 9.115 1.826-.037-.036-7.475 2.272.163-.132 7.271 6.254-.119-.201-6.384 3.58.16.054 6.146h1.49l-.2-7.72h1.965v7.695l1.395-.03-.106-6.778 2.627-.089-.092 6.822 1.461-.03-.007-8.836Zm.04 10.67-22.172.252.099 10.26 2.341-.044-.032-2.044L33 33.231v2.092l1.943-.037-.128-8.585 3.028-.059v2.248l1.461-.946 3.737 6.559-.044-9.46Zm-5.113 5.111.018 5.075 2.922-.06-2.94-5.015Zm5.168 6.834-22.099.248.088 9.92h1.75l-.212-8.128h1.928l.04 8.131h1.535l-.114-7.612 2.744-.107-.103 7.7h1.845l-.216-8.472 2.013.16.033 8.31h1.658l-.219-6.934 3.583.163.063 6.767h1.534l-.117-8.132 2.557-.093-.106 8.22h1.826l-.01-10.142Zm-18.358-7.342 8.613-.126.087 2.255-8.668-.11-.032-2.019Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgAspectLogic;
