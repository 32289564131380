import * as React from 'react';
import { SVGProps } from 'react';

const SvgSquare8 = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M3 12c0-7.412 1.588-9 9-9s9 1.588 9 9-1.588 9-9 9-9-1.588-9-9Z"
            stroke="currentColor"
            fill="none"
        />
        <path
            d="M13.39 11.63c.3.147.534.343.7.59.167.24.25.517.25.83v.22c0 .367-.1.69-.3.97-.193.28-.466.497-.82.65a3.04 3.04 0 0 1-1.22.23c-.466 0-.876-.077-1.23-.23a1.872 1.872 0 0 1-.82-.65 1.665 1.665 0 0 1-.29-.97v-.22c0-.313.084-.59.25-.83.167-.247.4-.443.7-.59a1.71 1.71 0 0 1-.61-.56 1.506 1.506 0 0 1-.21-.79v-.19c0-.54.2-.97.6-1.29.407-.32.944-.48 1.61-.48.667 0 1.2.16 1.6.48.4.32.6.75.6 1.29v.19c0 .293-.07.557-.21.79-.14.227-.34.413-.6.56Zm-2.67-1.37c0 .653.427.98 1.28.98.854 0 1.28-.327 1.28-.98v-.12c0-.313-.11-.553-.33-.72-.213-.167-.53-.25-.95-.25-.42 0-.74.083-.96.25-.213.167-.32.407-.32.72v.12Zm2.69 2.83a.841.841 0 0 0-.37-.73c-.246-.173-.593-.26-1.04-.26-.446 0-.796.087-1.05.26a.841.841 0 0 0-.37.73v.2c0 .307.124.547.37.72.247.167.597.25 1.05.25.447 0 .794-.083 1.04-.25a.832.832 0 0 0 .37-.72v-.2Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgSquare8;
