import * as React from 'react';
import { SVGProps } from 'react';

const SvgLady = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.39 8.99c.635-2.91-.71-5.545-2.946-5.975 1.22-1.085 4.15-.733 4.15 1.918 0 1.813-1.137 2.772.31 3.881-.428.364-1.033.39-1.515.177Zm-1.383 1.912c0-.591-.015-1.172-.057-1.721-.17.624-.439 1.147-.86 1.502l.206 1.24 2.449.951-.159.659-8.346.027-.143-.666 2.615-.984.146-1.28a2.887 2.887 0 0 1-.764-1.076c-.175.486-.257 1-.243 1.515-1.691-2.408-1.688-6.7 1.608-7.512.31-.093.631-.14.954-.137h.227c2.896.003 4.558 3.956 2.368 7.482ZM8.59 12.105c-.02-.25-.053-.76-.074-1.082-.313.115-.644.17-.977.165a2.822 2.822 0 0 1-1.063-.193c-.02.355-.055.955-.066 1.217-.04.796 2.243.81 2.175-.107h.005ZM5.522 8.842c.326 1.086.946 1.677 1.974 1.677 1.457 0 1.976-1.259 2.081-3.34-.176-.465-.42-.8-.751-.936-.43 1.677-2.289 1.44-3.304 2.599Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgLady;
