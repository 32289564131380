import * as React from 'react';
import { SVGProps } from 'react';

const SvgKnight = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m2.563 13.5-.149-.685.974-.35.201.403.89-.305-.176-.427 1.508-.544.122-1.11a4.709 4.709 0 0 1-.109-.162C5 10.123 4.827 9.18 5.207 8.598a8.874 8.874 0 0 1-.085-.705c-.522-.683-1.705-3.024.23-3.49-.207-1.257.813-1.844 1.583-1.21.582-1.108 2.891-.816 3.006.366a.907.907 0 0 1 1.098 1.258c.774.25.38 2.326-.18 3.072-.007.22-.019.427-.036.621.38.618.232 1.622-.532 1.826-.04.065-.081.122-.122.192l.184 1.104 1.461.525-.203.462.905.341.232-.469.838.301-.165.678-10.858.03Zm5.516-.5 1.633-.718L9.6 11.15a7.2 7.2 0 0 1-.817.64l-1.38.027a6.477 6.477 0 0 1-.86-.659l-.072 1.089L8.08 13Zm1.34-2.711a1.768 1.768 0 0 1-1.076-.941h-.62s-.13.756-1.08.956c.277.3.593.56.94.776l.914.03c.347-.225.658-.5.924-.816l-.003-.005Zm.8-3.044c-.37-.807-.62-1.866-.535-2.767-.68.587-2.61 1.498-3.576 1.02-.012.443-.181 1.022-.365 1.517.002.587.055 1.172.16 1.75.401.411 1.258-.017 1.545-.376l1.302.063c.318.433.923.562 1.319.365a9.794 9.794 0 0 0 .153-1.563l-.002-.009Z"
            fill="currentColor"
        />
    </svg>
);

export default SvgKnight;
